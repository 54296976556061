export class Document {
  fileName: string;
  Content_Type: string;
  data: any;
  constructor(fileName: string, Content_Type: string, data: string) {
    this.fileName = fileName;
    this.Content_Type = Content_Type;
    this.data = data;
  }
}
