import React from "react";
import { Link } from "react-router-dom";
import "./ValidateOtp.css";
import dclogo from "../../../assets/images/DClogo.png";
import "../../../CSS/Main.css";

interface ValidateOtpProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resendOtp: (event: any) => void;
  model: any;
}

const ValidateOtp: React.FC<ValidateOtpProps> = (
  validateOtpProps: ValidateOtpProps
) => {
  return (
    <div className=" d-flex align-items-center justify-content-center flex-column">
      <div className="container d-flex align-items-center justify-content-center flex-column">
        <div className="cardM">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div className="pt-5">
              <img className="pt-5" src={dclogo} alt="DC Logo"></img>
            </div>
            <p className="loginText pt-3">Verfication</p>
            <p className="pt-3 plainText">
              Please enter 6 digit code sent to your mobile number
            </p>
            <div className="d-flex pt-2">
              <div className="inpBox"></div>
              <input
                className="inputOtp"
                type="text"
                onInput={validateOtpProps.onChange}
              ></input>
            </div>
            <br />
            <p className="pt-3 otpText mb-0">Didn’t receive the OTP yet?</p>
            <p className="pt-1 resend" onClick={validateOtpProps.resendOtp}>
              Resend OTP
            </p>
            <div className="pt-3">
              <button className="sendBtn" onClick={validateOtpProps.onClick}>
                Login
              </button>
            </div>
            <div>
              <p className="pt-3 plainText">
                Don’t have an account yet?
                <Link
                  className="PlainTextExtra"
                  to="/Register"
                  onClick={() => {
                    validateOtpProps.model.setMany({
                      isOtpGenerated: false,
                      generateOtpResponse: undefined,
                    });
                  }}
                >
                  {" "}
                  Become our partner
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateOtp;
