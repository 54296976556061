import React from "react";
import "./App.css";
import { ComponentBase } from "resub";
import Login from "./View/containers/Login/Login";
import Home from "./View/containers/Home/Home";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { BrowserRouter as Router, Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import { DependencyInjector } from "./dependency-injector/DependencyInjector";
import { IBaseState, BaseState } from "./view-model/BaseState";
import Register from "./View/containers/Register/Register";
import { CircularProgress } from "@material-ui/core";

class App extends ComponentBase<any, IBaseState> {
  baseState: BaseState;
  constructor(props: any) {
    super(props);
    DependencyInjector.initialize();
    this.baseState = DependencyInjector.default().provideBaseState();
  }


  componentDidMount() {
    this.baseState.autoAuthenticate();
    if (this.props.location && this.props.location.pathname) {
      this.props.history.replace(
        this.props.location.pathname,
        this.props.location.state
      );
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div className="d-flex justify-content-center align-items-center col-12 px-0 height-100vh">
        <CircularProgress />
      </div>
    }
    return (
      <Router>
        <Switch>
          <Route
            path="/Login"
            exact
            render={(props: RouteComponentProps) => <Login baseState={this.state} {...props} routerProps={props} />}
          />
          <Route
            path="/Register"
            exact
            render={(props: RouteComponentProps) => <Register baseState={this.state} {...props} routerProps={props} />}
          />
          <ProtectedRoute
            path="/Home"
            render={(props: any) => {
              return <Home baseState={this.state} routerProps={props} {...props} />;
            }}
            isLoggedIn={this.state.isLoggedIn}
          />
          <Redirect to="/Login" from="/" />
        </Switch>
      </Router>
    );
  }
  protected _buildState() {
    return this.baseState.getState();
  }
}

export default App;
