enum Strings {
  empty_mobile_number_error = "Please enter mobile number",
  invalid_mobile_number_length_error = "Please enter 10 digit mobile number",
  enter_otp = "Enter OTP",
  check_your_mobile_for_otp = "Check your mobile for OTP",
  login = "Log In",
  mobile_number = "Mobile number",
  empty_otp_error = "Please enter otp",
  resend_otp = "Resend OTP",
  empty_name = "name cannot be empty!",
  validate_name='Please enter a valid name',
  invalid_email = "Please enter valid email ID",
  invalid_region = "select a valid region",
  invalid_manager = "select a valid Marketing manager",
  address_upload_error = "Please upload address proof",
  pancard_upload_error = "Please upload pancard",
  cancelled_cheque_upload_error = "Please upload cancelled cheque",
  select_terms_and_condition_error = "Please select the terms and condition",
  select_is_not_dc_employee_error = "Please confirm you are not design cafe employee",
  select_is_contacted_lead = "Please confirm you have contacted the lead.",
  invalid_gstno = "select a valid region",
  invalid_state = "select a valid state",
  empty_project_name = "select a valid project name",
  invalid_country = "select a valid country",
  empty_gstno = "gst no cannot be empty!",
  empty_pancard_no = "pancard no cannot be empty!",
  empty_street_no = "street no cannot be empty!",
  empty_zipcode = "zipcode cannot be empty!",
  invalid_zip_code="Please enter a valid postal zip code",
  check_box_inside_model = "Please read terms and condition and check the box",
  pan_error = "Please enter a valid PAN Number",
  gst_error = "Please enter a valid GST Number",
  empty_email_error = "Please enter email",
  file_format_error = "Please upload files with following format: jpeg, jpg, png and pdf",
  pdf_size_error = "Please upload PDF file with size less than 1.3 MB",
}
export default Strings;
