import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { DashboardRepository } from "../domain/repository/DashboardRepository";
import Constants from "../resources/Constants";

export interface IHomeState {
  isLoading: boolean;
}

export interface ILeadProps { }

export class HomeViewModel extends BaseViewModel {
  protected state: IHomeState;
  constructor(
    private baseState: BaseState,
    private dashboardRepository: DashboardRepository
  ) {
    super();
    this.state = this.defaultState();
  }

  protected setState(newState: IHomeState) {
    this.state = newState;
    this.trigger();
  }

  defaultState(): IHomeState {
    return {
      isLoading: false
    };
  }

  public logout(): void {
    localStorage.setItem(Constants.LOGGED_IN_TOKEN, "");
  }
}
