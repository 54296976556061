import React from "react";
import { ComponentBase } from "resub";
import {
  IRegisterState,
  RegisterViewModel,
} from "../../../view-model/RegisterViewModel";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import Documents from "../../signup/Documents/Documents";
import Information from "../../signup/Information/Information";
import Verification from "../../signup/Verification/Verification";
import ImageAssets from "../../../assets/ImageAssets";
import "./Register.css";
import SnackBarComponent from "../../../components/SnackBarComponent/SnackBarComponent";
import { isNullOrUndefined } from "util";
import { Document } from "../../../domain/model/Document";
import { Backdrop, CircularProgress } from "@material-ui/core";
import imageCompression from "browser-image-compression";
import { strict } from "assert";
import Strings from "../../../resources/Strings";

export default class Register extends ComponentBase<any, IRegisterState> {
  private viewModel: RegisterViewModel;

  constructor(props: any) {
    super(props);
    this.viewModel = DependencyInjector.default().provideRegisterViewModel();
  }

  componentDidMount(): void {
    this.viewModel.load();
  }
  sendOtpClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.viewModel.generateOtp();
  };

  validateOtpClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.viewModel.validateOtp();
    if (isNullOrUndefined(this.state.registerError)) {
      this.viewModel.getTerms();
    }
  };
  onTermsAccepted = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.viewModel.set("checkboxInsideModel", true);
    this.documentVerifyClicked(event);
  };
  documentVerifyClicked = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    this.viewModel.registerCustomer().then((value: any) => {
      if (value) {
        this.viewModel.setMany(this.viewModel.defaultState());
        this.props.history.push("/Login");
      }
    });
  };

  documentUploaded = async (event: any) => {
    event.stopPropagation();
    const element = event.target.id;
    if (event.target.type === "checkbox") {
      //for terms and condition and not employess of ds
      this.viewModel.set(element, event.target.checked);
    } else {
      // for document upload
      if (event.target?.files?.length > 0) {
        const file = event.target.files[0];
        const fileSize = event.target.files[0].size;

        const fileType = event.target.files[0].type.split("/")[1];

        if (fileType === undefined) {
          this.viewModel.set(
            "documentImagesError",
            Error(Strings.file_format_error)
          );
          return;
        }
        const allowedImageTypes =
          fileType.endsWith("jpeg") ||
          fileType.endsWith("jpg") ||
          fileType.endsWith("png") ||
          fileType.endsWith("pdf");
        if (fileType === "pdf" && fileSize > 1300000) {
          this.viewModel.set(
            "documentImagesError",
            Error(Strings.pdf_size_error)
          );
          return;
        } else if (!allowedImageTypes) {
          this.viewModel.set(
            "documentImagesError",
            Error(Strings.file_format_error)
          );
          return;
        }

        this.viewModel.set("isLoading", true);
        const options = {
          maxSizeMB: 1.3,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        try {
          if (fileType === "pdf") {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (imageLoadevent: ProgressEvent<FileReader>) => {
              const fileData: any = imageLoadevent.target?.result;
              const data = fileData.split(",");
              const fileExtension = data[0].split(":")[1].split(";")[0];
              const document = new Document(file.name, fileExtension, data[1]);
              if (element === "pancard") {
                this.viewModel.set("panCardInfo", document);
              } else if (element === "cancelledCheque") {
                this.viewModel.set("cancelledChequeInfo", document);
              } else if (element === "addressProof") {
                this.viewModel.set("addressProofInfo", document);
              }
            };
          } else {
            const compressedFile = await imageCompression(file, options);
            var reader = new FileReader();
            reader.readAsDataURL(compressedFile);
            reader.onload = (imageLoadevent: ProgressEvent<FileReader>) => {
              const fileData: any = imageLoadevent.target?.result;
              const data = fileData.split(",");
              const fileExtension = data[0].split(":")[1].split(";")[0];
              const document = new Document(file.name, fileExtension, data[1]);
              if (element === "pancard") {
                this.viewModel.set("panCardInfo", document);
              } else if (element === "cancelledCheque") {
                this.viewModel.set("cancelledChequeInfo", document);
              } else if (element === "addressProof") {
                this.viewModel.set("addressProofInfo", document);
              }
            };
          }
          this.viewModel.set("isLoading", false);
        } catch (error) {
          this.viewModel.set("isLoading", false);
        }
      }
    }
  };

  snackBarComponentClose = () => {
    this.viewModel.setMany({
      mobileNumberError: undefined,
      nameError: undefined,
      emailError: undefined,
      regionError: undefined,
      marketingManagerError: undefined,
      otpError: undefined,
      termsAndConditionError: undefined,
      notEmployeeOfDesignCafe: undefined,
      panCardUploadedError: undefined,
      cancelledChequeUploadedError: undefined,
      addressProofUploadedError: undefined,
      registerError: undefined,
      isTermsAndConditionClicked: false,
    });
  };

  mobileNumberChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const element = event.target.id;
    if (element === "mobileNumber") {
      this.viewModel.set("mobileNumber", value);
    } else if (element === "email") {
      this.viewModel.set("email", value);
    } else if (element === "region") {
      this.viewModel.set("region", value);
    } else if (element === "manager") {
      this.viewModel.set("marketingManagerId", value);
    } else if (element === "name") {
      this.viewModel.set("name", value);
    } else if (element === "otp") {
      this.viewModel.set("otp", value);
    } else if (element === "gstno") {
      this.viewModel.set("gstno", value);
    } else if (element === "panCardNumber") {
      this.viewModel.set("panCardNumber", value);
    } else if (element === "street") {
      this.viewModel.set("street", value);
    } else if (element === "state") {
      this.viewModel.set("state", value);
    } else if (element === "country") {
      this.viewModel.set("country", value);
    } else if (element === "zipcode") {
      this.viewModel.set("zipcode", value);
    }
  };

  render() {
    let snackBarComponent = null;
    let backDropWithCircularProgress = null;
    let step = (
      <Information
        onClick={this.sendOtpClicked}
        onChange={this.mobileNumberChangeHandler}
        regions={this.state.regions}
        marketingManager={this.state.marketingManagersInfo}
        states={this.state.states}
        countries={this.state.countries}
        city={this.state.region}
      />
    );
    if (this.state.step === "VERIFICATION") {
      step = (
        <Verification
          onClick={this.validateOtpClicked}
          onChange={this.mobileNumberChangeHandler}
          resendOtp={this.sendOtpClicked}
        />
      );
    } else if (this.state.step === "DOCUMENTS") {
      step = (
        <Documents
          pdfData={this.state.termsAndConditionsData}
          onClick={this.documentVerifyClicked}
          onChange={this.documentUploaded}
          onTermsAccepted={this.onTermsAccepted}
          isPanCardUploaded={!isNullOrUndefined(this.state.panCardInfo)}
          isAddressUploaded={!isNullOrUndefined(this.state.addressProofInfo)}
          isCancelledChequeUploaded={
            !isNullOrUndefined(this.state.cancelledChequeInfo)
          }
          model={this.viewModel}
          checkboxInsideModel={this.state.checkboxInsideModel}
        />
      );
    }

    let message: string | undefined = "";
    let open = false;
    if (!isNullOrUndefined(this.state.registerError)) {
      message = this.state.registerError.message;
      open = true;
      snackBarComponent = (
        <SnackBarComponent
          message={message}
          onClose={this.snackBarComponentClose}
          open={open}
          position={{ vertical: "bottom", horizontal: "center" }}
          severity="error"
          autoHideDuration="15000"
          routerProps={this.props.history}
        />
      );
    }

    if (this.state.isLoading) {
      backDropWithCircularProgress = (
        <Backdrop open={this.state.isLoading}>
          <CircularProgress />
        </Backdrop>
      );
    }

    return (
      <div>
        <div className="backDrop">{backDropWithCircularProgress}</div>
        <div className="row ml-0 mr-0">
          <div className="col-0 col-sm-0 col-mid-0 col-lg-0 col-xl-6 pl-0">
            <img
              className="signUpImg"
              src={ImageAssets.dc_Register_Image}
              alt="RegisterImage"
            />
          </div>
          <div className="col-0 col-sm-0 col-mid-0 col-lg-0 col-xl-6 no-gutters ">
            <div>{step}</div>
          </div>
          {snackBarComponent}

          {this.state.generateOtpResponse !== undefined && (
            <SnackBarComponent
              message={
                this.state.generateOtpResponse &&
                this.state.generateOtpResponse.message
              }
              onClose={() => {
                this.viewModel.set("generateOtpResponse", undefined);
              }}
              open={this.state.generateOtpResponse !== undefined ? true : false}
              position={{ vertical: "bottom", horizontal: "center" }}
              severity="success"
              autoHideDuration="15000"
              routerProps={this.props.history}
            />
          )}
        </div>
      </div>
    );
  }
  protected _buildState() {
    return this.viewModel.getState();
  }
}
