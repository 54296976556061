import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { DashboardRepository } from "../domain/repository/DashboardRepository";
import { ValidationUtils } from "../core/ValidationUtils";
import Messages from "../resources/Strings";
import { UserRepository } from "../domain/repository/userRepository";

export interface ISubmitLeadState {
  isLoading: boolean;
  regions: string[];
  name: string;
  lastName: string;
  mobileNumber: string;
  project_name__c: string;
  email: string;
  areaCode: string;
  notes: string;
  city: string;
  isContactedLead: boolean;
  isLeadSubmitedSucessfully: boolean;
  submitLeadError?: Error;
  isLeadSubmittedDuplicate: boolean
}

export interface ILeadProps {}

export class SubmitLeadViewModel extends BaseViewModel {
  protected state: ISubmitLeadState;
  constructor(
    private baseState: BaseState,
    private dashboardRepository: DashboardRepository,
    private userRepository: UserRepository,
    private validationUtils: ValidationUtils
  ) {
    super();
    this.state = this.defaultState();
  }

  protected setState(newState: ISubmitLeadState) {
    this.state = newState;
    this.trigger();
  }

  public async load(): Promise<void> {
    try {
      this.setState({
        ...this.state,
        isLoading: true,
      });
      const response = await this.userRepository.getManagerAndRegion();
      this.setMany({
        ...this.state,
        regions: response.body.regions,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
      });
      this.baseState.set("isLoading", false);
    }
  }

  protected defaultState(): ISubmitLeadState {
    return {
      isLoading: false,
      regions: [],
      name: "",
      lastName: "",
      mobileNumber: "",
      project_name__c: "",
      email: "",
      city: "",
      areaCode: "",
      notes: "",
      isContactedLead: false,
      isLeadSubmitedSucessfully: false,
      isLeadSubmittedDuplicate:false,
    };
  }

  private validateMobileNumber(): void {
    if (this.validationUtils.isEmpty(this.state.mobileNumber)) {
      this.setMany({
        submitLeadError: Error(Messages.empty_mobile_number_error),
      });
    } else if (
      !this.validationUtils.isMobileNumberLengthValid(this.state.mobileNumber)
    ) {
      this.setMany({
        submitLeadError: Error(Messages.invalid_mobile_number_length_error),
      });
    }
  }

  private validateSubmitLead(): void {
    this.validateMobileNumber();
    if (this.validationUtils.isEmpty(this.state.name)) {
      this.setMany({
        submitLeadError: Error(Messages.empty_name),
      });
    }
    if (this.validationUtils.isEmpty(this.state.city)) {
      this.setMany({
        submitLeadError: Error(Messages.invalid_region),
      });
    }
    if (this.validationUtils.isEmpty(this.state.project_name__c)) {
      this.setMany({
        submitLeadError: Error(Messages.empty_project_name),
      });
    }
    if (!this.state.isContactedLead) {
      this.setMany({
        submitLeadError: Error(Messages.select_is_contacted_lead),
      });
    }
  }

  public async submitLead(): Promise<void> {
    this.validateSubmitLead();
    if (this.state.submitLeadError) {
      return;
    }
    try {
      this.setMany({
        isLoading: true,
      });
      const data = {
        firstname: this.state.name,
        Last_Name: this.state.lastName,
        Mobile: this.state.mobileNumber,
        project_name__c: this.state.project_name__c,
        Region: this.state.city,
        Email: this.state.email,
        Area_Code: this.state.areaCode,
        Notes: this.state.notes,
      };
      let response=await this.dashboardRepository.submitLead(data);
      if(response.code === 409){
        this.setState({
          ...this.state,
          isLeadSubmittedDuplicate: true,
          isLoading:false,
        });

      }else{      
        this.setState({
          ...this.state,
          isLeadSubmitedSucessfully: true,
        });
      }

    } catch (error) {
        this.setState({
          ...this.state,
          isLoading: false,
          submitLeadError: Error(error.message),
        });
    }
  }
}
