import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { UserRepository } from "../domain/repository/userRepository";
import { ValidationUtils } from "../core/ValidationUtils";
import { MarketingManager } from "../domain/model/MarketingManager";
import Messages from "../resources/Strings";
import { isNullOrUndefined } from "util";

export interface IRegisterState {
  isLoading: boolean;
  step: string;
  regions: string[];
  marketingManagersInfo: MarketingManager[];
  states: string[];
  countries: string[];
  name: string;
  email: string;
  region: string;
  mobileNumber: string;
  otp: string;
  marketingManagerId: string;
  gstno: string;
  panCardNumber: string;
  street: string;
  state: string;
  country: string;
  zipcode: string;
  checkboxInsideModel: boolean;
  isNotEmployeeOfDesignCafeChecked: boolean;
  panCardInfo: string | undefined;
  cancelledChequeInfo: string | undefined;
  addressProofInfo: string | undefined;
  isTermsAndConditionClicked: boolean;
  nameError?: Error;
  registerError?: Error;
  termsAndConditionsData: string | undefined;
  generateOtpResponse: any;
  shouldShowModel: any;
  documentImagesError?: Error;
}

export class RegisterViewModel extends BaseViewModel {
  protected state: IRegisterState;
  constructor(
    private baseState: BaseState,
    private userRepository: UserRepository,
    private validationUtils: ValidationUtils
  ) {
    super();
    this.state = this.defaultState();
  }

  protected setState(newState: IRegisterState) {
    this.state = newState;
    this.trigger();
  }

  defaultState(): IRegisterState {
    return {
      isLoading: false,
      step: "",
      regions: [],
      marketingManagersInfo: [],
      states: [],
      countries: [],
      name: "",
      email: "",
      region: "",
      mobileNumber: "",
      otp: "",
      marketingManagerId: "",
      gstno: "",
      panCardNumber: "",
      street: "",
      state: "",
      country: "",
      zipcode: "",
      isNotEmployeeOfDesignCafeChecked: false,
      panCardInfo: undefined,
      checkboxInsideModel: false,
      cancelledChequeInfo: undefined,
      addressProofInfo: undefined,
      isTermsAndConditionClicked: false,
      termsAndConditionsData: "",
      generateOtpResponse: undefined,
      shouldShowModel: false,
      documentImagesError: undefined,
    };
  }
  public async load(): Promise<void> {
    try {
      const response = await this.userRepository.getManagerAndRegion();
      this.setMany({
        ...this.state,
        regions: response.body.regions,
        marketingManagersInfo: response.body.cmmRecords,
        states: response.body.states,
        countries: response.body.countries,
      });
    } catch (error) {
      this.baseState.set("isLoading", false);
    }
  }
  public async getTerms(): Promise<void> {
    try {
      const address =
        this.state.street +
        ", " +
        this.state.region +
        ", " +
        this.state.state +
        ", " +
        this.state.country +
        ", " +
        this.state.zipcode;
      const termsData = await this.userRepository.getTerms(
        this.state.name,
        address,
        this.state.panCardNumber,
        this.state.gstno
      );
      this.setMany({
        ...this.state,
        termsAndConditionsData: termsData.body.data,
      });
    } catch (error) {
      this.baseState.set("isLoading", false);
    }
  }
  private validateManager(): boolean {
    for (let { Id } of this.state.marketingManagersInfo) {
      if (Id === this.state.marketingManagerId) {
        return true;
      }
    }
    return false;
  }

  private validate(): void {
    if (this.validationUtils.isEmpty(this.state.name)) {
      this.setMany({
        registerError: Error(Messages.empty_name),
      });
      return;
    }
    if (this.validationUtils.isEmpty(this.state.email)) {
      this.setMany({
        registerError: Error(Messages.empty_email_error),
      });
      return;
    }
    if (!this.validationUtils.isEmailValid(this.state.email)) {
      this.setMany({
        registerError: Error(Messages.invalid_email),
      });
      return;
    }
    if (!this.state.regions.includes(this.state.region)) {
      this.setMany({
        registerError: Error(Messages.invalid_region),
      });
      return;
    }
    if (!this.validateManager()) {
      this.setMany({
        registerError: Error(Messages.invalid_manager),
      });
      return;
    }
    if (this.state.gstno.length !== 0) {
      if (!this.validationUtils.isVaidGSTNumber(this.state.gstno)) {
        this.setMany({
          registerError: Error(Messages.gst_error),
        });
        return;
      }
    }
    if (this.validationUtils.isEmpty(this.state.panCardNumber)) {
      this.setMany({
        registerError: Error(Messages.empty_pancard_no),
      });
      return;
    }

    if (!this.validationUtils.isValidPanNumber(this.state.panCardNumber)) {
      this.setMany({
        registerError: Error(Messages.pan_error),
      });
      return;
    }

    if (this.validationUtils.isEmpty(this.state.street)) {
      this.setMany({
        registerError: Error(Messages.empty_street_no),
      });
      return;
    }

    if (!this.state.states.includes(this.state.state)) {
      this.setMany({
        registerError: Error(Messages.invalid_state),
      });
      return;
    }
    if (!this.state.countries.includes(this.state.country)) {
      this.setMany({
        registerError: Error(Messages.invalid_country),
      });
      return;
    }

    if (this.validationUtils.isEmpty(this.state.zipcode)) {
      this.setMany({
        registerError: Error(Messages.empty_zipcode),
      });
      return;
    }
    if (!this.validationUtils.isValidPinCode(this.state.zipcode)) {
      this.setMany({
        registerError: Error(Messages.invalid_zip_code),
      });
      return;
    }
    if (!this.validationUtils.isValidName(this.state.name)) {
      this.setMany({
        registerError: Error(Messages.validate_name),
      });
      return;
    }

    this.validateMobileNumber();
  }

  private validateMobileNumber(): void {
    if (this.validationUtils.isEmpty(this.state.mobileNumber)) {
      this.setMany({
        registerError: Error(Messages.empty_mobile_number_error),
      });
      return;
    } else if (
      !this.validationUtils.isMobileNumberLengthValid(this.state.mobileNumber)
    ) {
      this.setMany({
        registerError: Error(Messages.invalid_mobile_number_length_error),
      });
      return;
    }
  }

  public async generateOtp(): Promise<void> {
    this.validate();
    if (this.state.registerError) {
      return;
    }
    try {
      this.setMany({
        isLoading: true,
      });
      const data = {
        phoneNumber: this.state.mobileNumber,
        isNewUser: true,
      };
      const response = await this.userRepository.generateOtp(data);
      this.setMany({
        isLoading: false,
        isOtpGenerated: true,
        generateOtpResponse: response.body,
        step: "VERIFICATION",
      });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
        registerError: error,
      });
      return;
    }
  }

  private checkForEmptyOtp(): void {
    if (this.validationUtils.isEmpty(this.state.otp)) {
      this.setState({
        ...this.state,
        registerError: Error(Messages.empty_otp_error),
      });
    }
  }

  public async validateOtp(): Promise<void> {
    this.checkForEmptyOtp();
    if (this.state.registerError) {
      return;
    }
    try {
      this.setMany({
        isLoading: true,
      });
      const data = {
        phoneNumber: this.state.mobileNumber,
        otp: this.state.otp,
        isNewUser: true,
      };
      await this.userRepository.verifyOtp(data);
      this.baseState.setMany({
        isLoading: false,
      });
      this.setMany({
        isLoading: false,
        step: "DOCUMENTS",
      });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
        registerError: error,
      });
    }
  }

  private registerRequestValidation() {
    if (isNullOrUndefined(this.state.addressProofInfo)) {
      this.setState({
        ...this.state,
        registerError: Error(Messages.address_upload_error),
      });
    }
    if (isNullOrUndefined(this.state.panCardInfo)) {
      this.setState({
        ...this.state,
        registerError: Error(Messages.pancard_upload_error),
      });
    }
    if (isNullOrUndefined(this.state.cancelledChequeInfo)) {
      this.setState({
        ...this.state,
        registerError: Error(Messages.cancelled_cheque_upload_error),
      });
    }
    if (!this.state.isNotEmployeeOfDesignCafeChecked) {
      this.setState({
        ...this.state,
        registerError: Error(Messages.select_is_not_dc_employee_error),
      });
    }
    if (!this.state.checkboxInsideModel) {
      this.setState({
        ...this.state,
        registerError: Error(Messages.check_box_inside_model),
      });
    }
  }

  public async registerCustomer(): Promise<any> {
    this.registerRequestValidation();
    if (this.state.registerError) {
      return Promise.resolve(false);
    }
    try {
      this.setMany({
        isLoading: true,
      });
      const documents = [];
      documents.push(this.state.panCardInfo);
      documents.push(this.state.addressProofInfo);
      documents.push(this.state.cancelledChequeInfo);

      const address = {
        street: this.state.street,
        city: this.state.region,
        state: this.state.state,
        country: this.state.country,
        zipcode: this.state.zipcode,
      };

      const data = {
        name: this.state.name,
        email: this.state.email,
        mobileNumber: this.state.mobileNumber,
        region: this.state.region,
        channelMarketingManager: this.state.marketingManagerId,
        TnCAggreed: this.state.checkboxInsideModel,
        TnCClickedTime: new Date().toLocaleString(),
        NoRelationship: this.state.isNotEmployeeOfDesignCafeChecked,
        documents: documents,
        gstNumber: this.state.gstno,
        panCardNumber: this.state.panCardNumber,
        address: address,
      };
      const response = await this.userRepository.registerCustomer(data);
      if (response.body.status === "Success") {
        this.setState({
          ...this.state,
          isLoading: false,
          step: "",
        });
      }
      return Promise.resolve(true);
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
        registerError: error,
        panCardInfo: undefined,
        cancelledChequeInfo: undefined,
        addressProofInfo: undefined,
      });
    }
  }
}
