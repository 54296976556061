import { ApiManager } from "../../api/ApiManager";

export class DashboardRepository {
  constructor(private apiManger: ApiManager) {}

  public async registerCustomer(data: object): Promise<any> {
    try {
      const response = await this.apiManger.registerCustomer(data);
      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
  /* */

  public async getLeads(
    pageIndex: number,
    pageSize: number,
    searchKey: string,
    searchOrder: boolean,
    filterKey: string,
    leadfilterKey: string,
    fromDate: string,
    toDate: string
  ): Promise<any> {
    try {
      const response = await this.apiManger.getLeads(
        pageIndex,
        pageSize,
        searchKey,
        searchOrder,
        filterKey,
        leadfilterKey,
        fromDate,
        toDate
      );

      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
  public async getProjects(
    pageIndex: number,
    pageSize: number,
    searchKey: string,
    searchOrder: boolean,
    filterKey: string,
    fromDate: string,
    toDate: string
  ): Promise<any> {
    try {
      const response = await this.apiManger.getProjects(
        pageIndex,
        pageSize,
        searchKey,
        searchOrder,
        filterKey,
        fromDate,
        toDate
      );

      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
  public async getOpportunity(
    pageIndex: number,
    pageSize: number,
    searchKey: string,
    searchOrder: boolean,
    filterKey: string,
    fromDate: string,
    toDate: string
  ): Promise<any> {
    try {
      const response = await this.apiManger.getOpportunity(
        pageIndex,
        pageSize,
        searchKey,
        searchOrder,
        filterKey,
        fromDate,
        toDate
      );
      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
  public async submitLead(data: object): Promise<any> {
    try {
      const response = await this.apiManger.submitLead(data);
      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
  public async getDashboardData(
    fromDate: string,
    toDate: string
  ): Promise<any> {
    try {
      const response = await this.apiManger.getDashboardData(fromDate, toDate);

      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
  public async getFaqs(searchKey: string): Promise<any> {
    try {
      const response = await this.apiManger.getFaqs(searchKey);
      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
  public async getSearchResults(
    searchKey: string,
    leadPage: number,
    opportunityPage: number,
    projectPage: number,
    pageSize: number
  ): Promise<any> {
    try {
      const response = await this.apiManger.getSearchResults(
        searchKey,
        leadPage,
        opportunityPage,
        projectPage,
        pageSize
      );
      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
}
