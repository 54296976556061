import React, { Component } from "react";
import { ComponentBase } from "resub";
import "./Dashboard.css";
import ImageAssets from "../../../assets/ImageAssets";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import {
  DashboardViewModel,
  IDashboardState,
} from "../../../view-model/DashboardViewModel";
import { isNullOrUndefined } from "util";
import { Bar, Doughnut } from "react-chartjs-2";
import { ChartColors } from "../../../resources/ChartColors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import { Button } from "@material-ui/core";
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import Leads from "../Leads/Leads";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

class Dashboard extends ComponentBase<any, IDashboardState> {
  private viewModel: DashboardViewModel;
  private leadChart: any;
  private opportunityChart: any;
  private projectChart: any;

  constructor(props: any) {
    super(props);
    this.viewModel = DependencyInjector.default().provideDashboardViewModel();
  }
  componentDidMount(): void {
    this.viewModel.load();
  }
  handleFromDateChange = (date: Date) => {
    this.viewModel.set("fromDate", date);
    this.viewModel.set("fromDateString", moment(date).format("YYYY-MM-DD"));
    this.viewModel.load();
  };
  handleToDateChange = (date: Date) => {
    this.viewModel.set("toDate", date);
    this.viewModel.set("toDateString", moment(date).format("YYYY-MM-DD"));
    this.viewModel.load();
  };

  clearFromTo = () => {
    this.viewModel.set("fromDate", undefined);
    this.viewModel.set("fromDateString", "");
    this.viewModel.set("toDate", undefined);
    this.viewModel.set("toDateString", "");
    this.viewModel.load();
  };
  /* bar chart */

  // getChartData(entities: any[]) {
  //   const data = {
  //     labels: [""],
  //     datasets: [
  //       {
  //         data: [0],
  //         backgroundColor: [""],
  //       },
  //     ],
  //   };

  //   let labels: string[] = [];
  //   let dataSet: number[] = [];
  //   let colors: string[] = [];
  //   for (let entity of entities) {
  //     labels.push(entity.status);
  //     dataSet.push(entity.count);
  //     for (let obj of ChartColors) {
  //       if (entity.status === obj.key) {
  //         colors.push(obj.color);
  //       }
  //     }
  //   }
  //   data.labels = labels;
  //   data.datasets[0].data = dataSet;
  //   data.datasets[0].backgroundColor = colors;
  //   const options = {
  //     legend: {
  //       position: "right",
  //     },
  //     plugins: {
  //       datalabels: {
  //         color: (context: any) => {
  //           var index = context.dataIndex;
  //           var value = context.dataset.backgroundColor[index];
  //           if (value) {
  //             var cValue = value.substring(1);
  //             var rgb = parseInt(cValue, 16);
  //             var r = (rgb >> 16) & 0xff;
  //             var g = (rgb >> 8) & 0xff;
  //             var b = (rgb >> 0) & 0xff;
  //             var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  //             if (luma <= 127) {
  //               return "white";
  //             } else {
  //               return "black";
  //             }
  //           } else {
  //             return "white";
  //           }
  //         },
  //         labels: {
  //           title: {
  //             font: {
  //               weight: "bold",
  //               size: "16",
  //             },
  //           },
  //         },
  //       },
  //     },
  //   };
  //   // return <Doughnut data={data} options={options} />;
  // }
  HandleLead = () => {
    this.props.history.push("/Home/Leads", {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      filter: "Connected",
      leadfilter: "Prospect",
    });
  };
  MeetingScheduled = () => {
    this.props.history.push("/Home/Leads", {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      filter: "Meeting Scheduled",
    });
  };
  MeetingDone = () => {
    this.props.history.push("/Home/Opportunities");
  };
  HandleSIgnUp = () => {
    this.props.history.push("/Home/Projects", {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      filter: "All",
    });
  };

  HandleChange = (event: any) => {
    const element = event.target.id;
    if (element === "Connected") {
      this.props.history.push("/Home/Leads", {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        filter: "Connected",
      });
    } else if (element === "Not-Connected") {
      this.props.history.push("/Home/Leads", {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        filter: "Not-Connected",
      });
    } else if (element === "Prospect") {
      this.props.history.push("/Home/Leads", {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        filter: "Prospect",
      });
    } else if (element === "Meetings-Scheduled") {
      this.props.history.push("/Home/Leads", {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        filter: "Meetings-Scheduled",
      });
    } else if (element === "Meetings-Confirmed") {
      this.props.history.push("/Home/Leads", {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        filter: "Meetings-Confirmed",
      });
    } else if (element === "Converted") {
      this.props.history.push("/Home/Leads", {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        filter: "Converted",
      });
    }
  };
  ChartClick = () => {
    this.props.history.push("/Home/Projects");
  };

  render() {
    console.log(this.props, "props");
    // console.log("chart", this.state.chartData);
    console.log(this.state.dashboardData, "Sfsdf");
    // console.log("chart m", this.state.chartData);
    let chartData = this.state.chartData;
    console.log(chartData);
    // console.log(chartData!.opportunities);

    // chartData Leads
    let meetingScheduledData2 =
      this.state.dashboardData?.meetingScheduled !== undefined
        ? this.state.dashboardData?.meetingScheduled
        : 0;
    let MeetingScheduledData = 0;
    MeetingScheduledData =
      chartData!.leads !== undefined && chartData!.leads?.length > 0
        ? chartData!.leads?.find(
            (event: any) => event.status == "Meeting Scheduled"
          )?.count || 0
        : 0;
    // console.log("meet", MeetingScheduledData);
    let leadcount = [
      this.state.dashboardData.New !== undefined
        ? this.state.dashboardData.New
        : 0,
      this.state.dashboardData.connected !== undefined
        ? this.state.dashboardData.connected
        : 0,
      this.state.dashboardData.notConnected !== undefined
        ? this.state.dashboardData.notConnected
        : 0,

      meetingScheduledData2,

      this.state.dashboardData.converted !== undefined
        ? this.state.dashboardData.converted
        : 0,
    ];

    // const leadSummary: any = [
    //   leadcount,
    //   0,
    //   0,
    //   prospectcount,
    //   meetingScheduledcount,
    //   meetingDonecount,
    //   signupecount,
    // ];

    //opportunity summary Data
    //
    let closeWonData =
      chartData!.opportunities !== undefined &&
      chartData!.opportunities.length > 0
        ? chartData!.opportunities.find(
            (event: any) => event.status == "Closed Won"
          )
        : 0;
    //console.log(closeWonData);
    let closedWonData2 = closeWonData == undefined ? 0 : closeWonData?.count;

    //Proposal Sent
    let proposalSentData =
      chartData!.opportunities !== undefined &&
      chartData!.opportunities.length > 0
        ? chartData!.opportunities.find(
            (event: any) => event.status == "Proposal Sent"
          )
        : 0;
    let proposalSentData2 =
      proposalSentData == undefined ? 0 : proposalSentData?.count;
    //Awaiting Closure
    let awaitingClosureData =
      chartData!.opportunities !== undefined &&
      chartData!.opportunities.length > 0
        ? chartData!.opportunities.find(
            (event: any) => event.status == "Awaiting Closure"
          )
        : 0;
    let awaitingClosureData2 =
      awaitingClosureData == undefined ? 0 : awaitingClosureData?.count;

    //Meeting Done
    let meetingDoneData =
      chartData!.opportunities !== undefined &&
      chartData!.opportunities.length > 0
        ? chartData!.opportunities.find(
            (event: any) => event.status == "Meeting Done"
          )
        : 0;

    let meetingDoneData2 =
      meetingDoneData == undefined ? 0 : meetingDoneData.count;
    console.log("meetd", meetingDoneData2);

    //Follow Up
    let followUpData =
      chartData!.opportunities !== undefined &&
      chartData!.opportunities.length > 0
        ? chartData!.opportunities.find(
            (event: any) => event.status == "Follow Up"
          )
        : 0;

    let followUpData2 = followUpData == undefined ? 0 : followUpData.count;

    //Discussion
    let DiscussionData =
      chartData!.opportunities !== undefined &&
      chartData!.opportunities.length > 0
        ? chartData!.opportunities.find(
            (event: any) => event.status == "Discussion"
          )
        : 0;

    let DiscussionData2 =
      DiscussionData == undefined ? 0 : DiscussionData.count;
    console.log("meetd", meetingDoneData2);

    //Closed Lost
    let ClosedLostData =
      chartData!.opportunities !== undefined &&
      chartData!.opportunities.length > 0
        ? chartData!.opportunities.find(
            (event: any) => event.status == "Closed Lost"
          )
        : 0;

    let ClosedLostData2 =
      ClosedLostData == undefined ? 0 : ClosedLostData.count;

    // Table Data
    let opportunity = [
      meetingDoneData2 !== undefined ? meetingDoneData2 : 0,
      proposalSentData2 !== undefined ? proposalSentData2 : 0,
      followUpData2 !== undefined ? followUpData2 : 0,
      DiscussionData2 !== undefined ? DiscussionData2 : 0,
      awaitingClosureData2 !== undefined ? awaitingClosureData2 : 0,
      closedWonData2 !== undefined ? closedWonData2 : 0,
      ClosedLostData2 !== undefined ? ClosedLostData2 : 0,
    ];

    let totalOpportunity =
      meetingDoneData2 +
      proposalSentData2 +
      followUpData2 +
      DiscussionData2 +
      awaitingClosureData2 +
      closedWonData2 +
      ClosedLostData2;
    console.log(totalOpportunity);
    // Project summary Data
    // let signOffdata = signOff !== undefined ? signOff : 0;
    //Project Sign-up
    let signOff =
      chartData!.projects !== undefined && chartData!.projects.length > 0
        ? chartData!.projects.find(
            (event: any) => event.status == "Project Signup"
          )
        : 0;
    let signOff2 = signOff == undefined ? 0 : signOff.count;
    //Design Finalization
    let designFinalization =
      chartData!.projects !== undefined && chartData!.projects.length > 0
        ? chartData!.projects.find(
            (event: any) => event.status == "Design Finalization"
          )
        : 0;
    let designFinalizationData =
      designFinalization == undefined ? 0 : designFinalization.count;
    //Design Signoff
    let designSignoff =
      chartData!.projects !== undefined && chartData!.projects.length > 0
        ? chartData!.projects.find(
            (event: any) => event.status == "Design Sign-Off"
          )
        : 0;
    let designSignoffData =
      designSignoff == undefined ? 0 : designSignoff.count;
    //Factory Dispatch
    let factoryDispatch =
      chartData!.projects !== undefined && chartData!.projects.length > 0
        ? chartData!.projects.find((event: any) => event.status == "Handover")
        : 0;
    let factoryDispatchData =
      factoryDispatch == undefined ? 0 : factoryDispatch.count;

    //Project Summary DATA
    let Project = [
      signOff2 !== undefined ? signOff2 : 0,
      designFinalizationData !== undefined ? designFinalizationData : 0,
      designSignoff !== undefined ? designSignoffData : 0,
      factoryDispatch !== undefined ? factoryDispatchData : 0,
    ];
    console.log(Project);
    //console.log(signOff);
    // console.log("table", this.state.dashboardData.leadsCount);

    //Lead Chart

    const state1 = {
      options: {
        chart: {
          id: "bar",
          events: {},
        },
        colors: ["#72593f"],
        legend: {
          onItemClick: {
            toggleDataSeries: true,
            //click: this.props.history.push("/Home/projects"),
          },
        },

        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 10,
                  color: undefined,
                },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0,
            },
          },
        },

        xaxis: {
          // min: 0,
          // max: 10,
          //range: 2,
          tickAmount: 5,
          categories: [
            "New Leads",
            "Connected",
            "Not Connected",
            "Meetings Scheduled",
            "Converted",
          ],
        },
      },
      series: [
        {
          name: "Count",
          data: leadcount,
        },
      ],
    };

    // opportunity chart
    const state2 = {
      options2: {
        chart: {
          id: "bar",
          events: {},
        },
        legend: {
          onItemClick: {
            toggleDataSeries: true,
            //click: this.props.history.push("/Home/projects"),
          },
        },

        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 10,
                  color: undefined,
                },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0,
            },
          },
        },

        xaxis: {
          min: 0,
          // max: 10,
          // range: 4,
          tickAmount: 5,
          categories: [
            "Meeting Done",
            "Proposal Sent",
            "Follow Up",
            "Discussion",
            "Awaiting Closure",
            "Closed Won",
            "Closed Lost",
          ],
        },
      },
      series2: [
        {
          name: "Count",
          data: opportunity,
        },
      ],
    };

    //project chart
    const state3 = {
      options3: {
        chart: {
          id: "bar",
          events: {},
        },
        colors: ["#72593f"],
        legend: {
          onItemClick: {
            toggleDataSeries: true,
            //click: this.props.history.push("/Home/projects"),
          },
        },

        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            colors: {
              ranges: [
                {
                  from: 0,
                  to: 10,
                  color: undefined,
                },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 0,
            },
          },
        },

        xaxis: {
          min: 0,
          //max: 20,
          // range: 4,
          tickAmount: 5,
          categories: [
            // "Project Signup",
            "Design Finalization",
            "Design Signoff",
            "Factory Dispatch",
          ],
        },
      },
      series3: [
        {
          name: "Count",
          data: Project,
        },
      ],
    };

    // const data2: any = {
    //   labels: [
    //     "Project Signup",
    //     "Design Finalization",
    //     "Design Signoff",
    //     "Factory Dispatch",
    //   ],

    //   datasets: [
    //     {
    //       label: "Count",
    //       fill: false,
    //       lineTension: 0.5,
    //       backgroundColor: "#5bc9d9",
    //       borderColor: "#5bc9d9",
    //       borderWidth: 1.5,
    //       borderCapStyle: "butt",
    //       borderDash: [],
    //       borderDashOffset: 0.0,
    //       borderJoinStyle: "miter",
    //       pointBorderColor: "#5bc9d9",
    //       pointBackgroundColor: "#fff",
    //       pointBorderWidth: 1,
    //       pointHoverRadius: 5,
    //       pointHoverBackgroundColor: "#5bc9d9",
    //       pointHoverBorderColor: "#5bc9d9",
    //       pointHoverBorderWidth: 2,
    //       pointRadius: 1,
    //       pointHitRadius: 10,
    //       indexAxis: "y" as const,
    //       data: [2, 3, 5, 4, 0],
    //     },
    //   ],
    // };
    // if (!isNullOrUndefined(this.state.chartData)) {
    //   if (this.state.chartData.length !== 0) {
    //     if (!isNullOrUndefined(this.state.chartData!.leads)) {
    //       this.leadChart = this.getChartData(this.state.chartData!.leads);
    //     }
    //     if (!isNullOrUndefined(this.state.chartData!.opportunities)) {
    //       this.opportunityChart = this.getChartData(
    //         this.state.chartData!.opportunities
    //       );
    //     }
    //     if (!isNullOrUndefined(this.state.chartData!.projects)) {
    //       let dataSet: any[] = [];
    //       let colors: string[] = [];
    //       for (let entity of this.state.chartData!.projects) {
    //         dataSet.push({ name: entity.status, value: entity.count });
    //         for (let obj of ChartColors) {
    //           if (entity.status === obj.key) {
    //             colors.push(obj.color);
    //           }
    //         }
    //       }
    //       this.projectChart = (
    //         <div className="funnelChart">
    //           {" "}
    //           <FunnelChart
    //             pallette={colors}
    //             chartWidth={300}
    //             chartHeight={100}
    //             heightRelativeToValue={false}
    //             data={dataSet}
    //           />
    //         </div>
    //       );
    //     }
    //   }
    // }
    console.log(this.state.dashboardData, "sfdfsddfs");
    return this.state.dashboardData ? (
      <div>
        <div className="welcomeWrap">
          <div className="welcomeBoard d-flex justify-content-center align-items-center">
            <div className="ml-0 ">
              <div className="row mt-2 ml-0 mr-0">
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6 d-flex justify-content-center align-items-center">
                  <p className="welcomeText">
                    Welcome{" "}
                    <span className="welcomeName">
                      {this.state.dashboardData.name}!
                    </span>
                  </p>
                </div>
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6 d-flex justify-content-center align-items-center">
                  <button
                    className="submitLead ml-4"
                    onClick={() => {
                      this.props.history.push("/Home/submitLeads");
                    }}
                  >
                    Submit your Lead
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="float-right mt-3">
          <div className="row">
            <div className="col-sm-4 col-md-0 col-lg-0 col-xl-0 ">
              <div className="d-flex">
                <p className="pr-2 dateText">From</p>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="dateInput pt-5 pt-lg-2"
                  selected={this.state.fromDate}
                  onChange={this.handleFromDateChange}
                />
              </div>
            </div>
            <div className="col-sm-4 col-md-0 col-lg-0 col-xl-0 pl-3 pl-sm-3 pl-md-4 pl-lg-4 pl-xl-4 ">
              <div className="d-flex">
                <p className="pr-2 pr-4 dateText">To</p>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="dateInput pt-5 pt-lg-2"
                  selected={this.state.toDate}
                  onChange={this.handleToDateChange}
                  minDate={this.state.fromDate}
                />
              </div>
            </div>
            <div className="col-sm-4 col-md-0 col-lg-0 col-xl-0 pl-3 pl-sm-3 pl-md-4 pl-lg-4 pl-xl-4 pt-4 pt-sm-5 pt-md-5 pt-lg-0 pt-xl-0">
              <button
                className="dashboard-all-time-button"
                onClick={this.clearFromTo}
              >
                All Time
              </button>
            </div>
          </div>
        </div>
        <div className="mobileTop">
          <div className="mt-5 pt-5 mobileCenter">
            <div className="row pt-2 ml-0 mr-0">
              <div
                className="col-0 col-sm-6 col-md-6 col-lg-6 col-xl-3 pb-3"
                onClick={this.HandleLead}
              >
                <Tooltip title="Leads" placement="top">
                  <div className="gboxWrap d-flex">
                    <div className="greenboxline"></div>
                    <div className="greenBox">
                      <div className="d-flex">
                        <div className="greenCircle">
                          <img
                            src={ImageAssets.dc_GreenBoxImage_Image}
                            alt="User Logo"
                          ></img>
                        </div>
                        <div>
                          <span className="totalNumber pl-3">
                            {this.state.dashboardData.leadsCount}
                          </span>
                          <br />
                          <span className="totalText">Leads</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </div>

              <div
                className="col-0 col-sm-6 col-md-6 col-lg-6 col-xl-3 pb-3"
                onClick={this.HandleLead}
              >
                <div className="rboxWrap d-flex">
                  <div className="redboxline"></div>
                  <div className="redBox">
                    <div className="d-flex">
                      <div className="redCircle">
                        <img
                          src={ImageAssets.dc_RedBoxImage_Image}
                          alt="User Logo"
                        ></img>
                      </div>
                      <div className="ml-33">
                        <span className="rTotalNumber ml-3">
                          {/* {
                            this.state.dashboardData.prospects === undefined
                              ? "0"
                              : this.state.dashboardData.prospects} */}
                          {this.state.dashboardData.prospectyt || 0}
                        </span>
                        <br />
                        <span className="rTotalText ml-3">Prospects</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-0 col-sm-6 col-md-6 col-lg-6 col-xl-3 pb-3 "
                onClick={this.MeetingScheduled}
              >
                <div className="gboxWrap d-flex">
                  <div className="greenboxline"></div>
                  <div className="greenBox">
                    <div className="d-flex">
                      <div className="greenCircle">
                        <img
                          src={ImageAssets.dc_RedBoxImage_Image}
                          alt="User Logo"
                        ></img>
                      </div>
                      <div className="ml-3">
                        <span className="grTotalNumber ">
                          {meetingScheduledData2}
                        </span>
                        <br />
                        <span className="grTotalText">Meeting Scheduled</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-0 col-sm-6 col-md-6 col-lg-6 col-xl-3 pb-3"
                onClick={this.MeetingDone}
              >
                <div className="rboxWrap d-flex">
                  <div className="redboxline"></div>
                  <div className="redBox">
                    <div className="d-flex">
                      <div className="redCircle">
                        <img
                          src={ImageAssets.dc_GreenBoxImage2_Image}
                          alt="User Logo"
                        ></img>
                      </div>
                      <div>
                        <span className="rTotalNumber pl-3">
                          {this.state.dashboardData.opportunity}
                        </span>
                        <br />
                        <span className="rTotalText">Meeting Done</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                className="col-0 col-sm-6 col-md-6 col-lg-6 col-xl-2 pb-3"
                onClick={this.HandleSIgnUp}
              >
                <div className="gboxWrap d-flex">
                  <div className="greenboxline"></div>
                  <div className="greenBox">
                    <div className="d-flex">
                      <div className="greenCircle">
                        <img
                          src={ImageAssets.dc_RedBoxImage2_Image}
                          alt="User Logo"
                        ></img>
                      </div>
                      <div className="ml-3">
                        <span className="totalNumber">
                          {this.state.dashboardData.signUp}
                        </span>
                        <br />
                        <span className="grTotalText">SignUp</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="mobileTop-1">
          <div className="mobileCenter">
            <div className="row pt-2 ml-0 mr-0">
              <div className="col-0 col-sm-0 col-md-6 col-lg-6 col-xl-4 pt-4">
                <div className="rboxWrap d-flex">
                  <div className="redboxline-2"></div>
                  <div className="redBox-2">
                    <div className="d-flex">
                      <div style={{ width: "90%" }}>
                        {/* <span className="rTotalNumber pl-3">
                          {this.state.dashboardData.meetingDone}
                        </span>
                        <br /> */}
                        <span className="totalText2">Leads Summary</span>
                        <Chart
                          options={state1.options}
                          series={state1.series}
                          type="bar"
                          height={250}
                          // width={350}
                        />
                        {/* <ul>
                          <li onClick={this.HandleLead}>
                            Leads :
                            <span className="totalText2">
                              {" "}
                              {this.state.dashboardData.leadsCount}{" "}
                            </span>
                          </li>
                          <li onClick={this.HandleChange} id="Connected">
                            Connected :{" "}
                            <span className="totalText2">
                              {" "}
                              {this.state.dashboardData.leadsCount}{" "}
                            </span>
                          </li>
                          <li id="Not-Connected" onClick={this.HandleChange}>
                            Not Connected :
                          </li>
                          <li id="Prospect" onClick={this.HandleChange}>
                            Prospect :
                          </li>
                          <li
                            id="Meetings-Scheduled"
                            onClick={this.HandleChange}
                          >
                            Meetings Scheduled :{" "}
                            <span className="totalText2">
                              {this.state.dashboardData.meetingScheduled}
                            </span>
                          </li>
                          <li
                            id="Meetings-Confirmed"
                            onClick={this.HandleChange}
                          >
                            Meetings Confirmed :
                          </li>
                          <li id="Converted" onClick={this.HandleChange}>
                            Converted :
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-0 col-sm-0 col-md-6 col-lg-6 col-xl-4 pt-4">
                <div className="gboxWrap d-flex">
                  <div className="greenboxline-2"></div>
                  <div className="greenBox-2">
                    <div className="d-flex">
                      <div style={{ width: "90%" }}>
                        {/* <span className="totalNumber">
                          {this.state.dashboardData.signUp}
                        </span>
                        <br /> */}
                        <span className="grTotalText2">
                          Opportunity Summary
                        </span>
                        <Chart
                          options={state2.options2}
                          series={state2.series2}
                          type="bar"
                          height={250}
                          // width={350}
                        />
                        {/* <Bar
                          ref="chart"
                          data={data}
                          width={350}
                          height={250}
                          onElementsClick={this.ChartClick}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-0 col-sm-0 col-md-6 col-lg-6 col-xl-4 pt-4">
                <div className="rboxWrap d-flex">
                  <div className="redboxline-2"></div>
                  <div className="redBox-2-2">
                    <div className="d-flex">
                      <div style={{ width: "90%" }}>
                        {/* <span className="rTotalNumber pl-3">
                          {this.state.dashboardData.meetingDone}
                        </span>
                        <br /> */}

                        <span className="totalText2">Project Summary</span>
                        <Chart
                          options={state3.options3}
                          series={state3.series3}
                          type="bar"
                          height={250}
                          //width={350}
                        />
                      </div>

                      <div>
                        {/* <Bar
                          ref="chart"
                          data={data2}
                          width={350}
                          height={250}
                        /> */}
                        {/* <ul>
                          <li>Project Signup :</li>
                          <li>Design Finalization :</li>
                          <li>Design Signoff :</li>
                          <li>Factory Dispatch :</li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="pt-5 ">
          <div className="row ml-0 mr-0">
            <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-9 pb-3">
              <div className="gboxWrap d-flex">
                <div className="greenboxlineLarge"></div>
                <div className="greenBoxLarge d-flex justify-content-between align-items-center">
                  <div className="pl-1 pl-sm-1 pl-md-3 pl-lg-4 pl-xl-4">
                    <p className="numberTotalLarge">
                      {this.state.dashboardData.commissionsDue}
                    </p>
                    <span className="numberTotalTextLarge">Commission Due</span>
                  </div>
                  <div>
                    <p className="numberTotalLarge">
                      {this.state.dashboardData.commissionsPaid}
                    </p>
                    <span className="numberTotalTextLarge">
                      Commission Paid
                    </span>
                  </div>
                  <div className="pr-1 pr-sm-1 pr-md-3 pr-lg-4 pr-xl-4">
                    <p className="numberTotalLarge">
                      {this.state.dashboardData.gstAmount}
                    </p>
                    <span className="numberTotalTextLarge">
                      Revenue without GST
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-3 pb-3">
              <div className="rboxWrap d-flex">
                <div className="redboxlineLarge"></div>
                <div className="redBoxLarge d-flex justify-content-center align-items-center">
                  <div className="">
                    <p className="redNumberTotalLarge">
                      {this.state.dashboardData.projectsCompleted}
                    </p>
                    <span className="redNumberTotalTextLarge ">
                      Projects Completed
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          /////////////////////////
        </div> */}
        {/* <div className="pt-5">
          <div className="row ml-0 mr-0">
            {this.state.chartData!.leads &&
            this.state.chartData!.leads.length > 0 ? (
              <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-4 pb-3">
                <div className="leadsGraph">
                  <div className="leadsGraphText">
                    <p className="pt-1">Leads</p>
                  </div>
                  <div className="mt-0 mt-sm-0 mt-md-3 mt-lg-3 mt-xl-5">
                    {this.leadChart}
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.chartData!.opportunities &&
            this.state.chartData!.opportunities.length > 0 ? (
              <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-4 pb-3">
                <div className="leadsGraph">
                  <div className="leadsGraphText">
                    <p className="pt-1">Opportunities</p>
                  </div>
                  <div className="mt-5">{this.opportunityChart}</div>
                </div>
              </div>
            ) : null}
            {this.state.chartData!.projects &&
            this.state.chartData!.projects.length > 0 ? (
              <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-4 pb-3">
                <div className="leadsGraph">
                  <div className="leadsGraphText">
                    <p className="pt-1">Projects</p>
                  </div>
                  {this.projectChart}
                </div>
              </div>
            ) : null}
          </div>
        </div> */}
      </div>
    ) : null;
  }

  protected _buildState() {
    return this.viewModel.getState();
  }
}
export default Dashboard;
