import React from "react";
import { NavLink } from "react-router-dom";
import "./NavigationItem.css";
import ".././NavigationItems.css";

interface NavigationItemProps {
  link: string;
  exact?: any;
  children: React.ReactNode;
}

const NavigationItem: React.FC<NavigationItemProps> = (navigationItemProps: NavigationItemProps) => {
  return (
    <div className="">
      <NavLink exact={navigationItemProps.exact} to={navigationItemProps.link} className="norma" activeClassName="activeNav">
        {navigationItemProps.children}
      </NavLink>
    </div>
  );
};
export default NavigationItem;
