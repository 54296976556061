import { ApiManager } from "../../api/ApiManager";
import Constants from "../../resources/Constants";

export class UserRepository {
  constructor(private apiManger: ApiManager) {}

  async generateOtp(data: any): Promise<any> {
    try {
      const response = await this.apiManger.generateOtp(data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async verifyOtp(data: any): Promise<any> {
    try {
      const response = await this.apiManger.verifyOtp(data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async autoAuthenticate(): Promise<any> {
    try {
      const token = localStorage.getItem(Constants.LOGGED_IN_TOKEN);
      if (token) {
        const response = await this.apiManger.autoAuthenticate();
        localStorage.setItem(
          Constants.LOGGED_IN_TOKEN,
          response.body.partner.loginToken
        );
        return response;
      }
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }

  public async getManagerAndRegion(): Promise<any> {
    try {
      const response = await this.apiManger.getManagerAndRegion();
      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
  public async getProfile(): Promise<any> {
    try {
      const response = await this.apiManger.getProfile();
      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
  
  public async acceptTerms(
    data: any,
  ): Promise<any> {
    try {
      const response = await this.apiManger.acceptTerms(
        data
      );
      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }

  public async getTerms(
    name?: string,
    address?: string,
    panNumber?: string,
    gstId?: string
  ): Promise<any> {
    try {
      const response = await this.apiManger.getTerms(
        name,
        address,
        panNumber,
        gstId
      );
      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }

  public async registerCustomer(data: object): Promise<any> {
    try {
      const response = await this.apiManger.registerCustomer(data);
      return response;
    } catch (error) {
      throw error;
      // this.baseState.set("isLoading", false);
    }
  }
}
