import React from "react";
import dclogo from "../../../assets/images/DClogo.png";
import "./Information.css";
import { MarketingManager } from "../../../domain/model/MarketingManager";

interface InformationProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChange: (event: any) => void;
  regions: string[];
  marketingManager: MarketingManager[];
  states: string[];
  countries: string[];
  city: string;
}

const Information: React.FC<InformationProps> = (
  informationProps: InformationProps
) => {
  const regions: any = [];
  const marketingManager: any[] = [];
  const states: any[] = [];
  const countries: any[] = [];

  for (let val of informationProps.regions) {
    regions.push(
      <option key={val} value={val}>
        {val}
      </option>
    );
  }

  for (let { Id, Name } of informationProps.marketingManager) {
    marketingManager.push(
      <option key={Id} value={Id}>
        {Name}
      </option>
    );
  }

  for (let val of informationProps.states) {
    states.push(
      <option key={val} value={val}>
        {val}
      </option>
    );
  }

  for (let val of informationProps.countries) {
    countries.push(
      <option key={val} value={val}>
        {val}
      </option>
    );
  }

  return (
    <div className=" d-flex align-items-center justify-content-center flex-column">
      <div className="container d-flex align-items-center justify-content-center flex-column">
        <div className="cardSignup">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div className="pt-3">
              <img className="" src={dclogo} alt="DC_Logo"></img>
            </div>
            <p className="loginText pt-3">Become our Patner</p>
            <div className="">
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <p className="signupText">Personal Information</p>
                  <div className="infoBar"></div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <p className="signupText-blur">Documents</p>
                  <div className="infoBar-blur"></div>
                </div>
              </div>
            </div>
            <div className="formMain pt-3">
              <div className="form-row">
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel">Name</label>
                  <div className="d-flex">
                    <div className="inpBox"></div>
                    <input
                      className="inputSignup"
                      type="text"
                      id="name"
                      onKeyUp={informationProps.onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel ">Email</label>
                  <div className="d-flex  ">
                    <div className="inpBox"></div>
                    <input
                      className="inputSignup"
                      type="text"
                      id="email"
                      onKeyUp={informationProps.onChange}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="formMain pt-3">
              <div className="form-row">
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel">Region</label>
                  <div className="d-flex">
                    <div className="inpBox"></div>
                    <select
                      className="inputSignup"
                      id="region"
                      onChange={informationProps.onChange}
                    >
                      <option value="">Select one--</option>
                      {regions}
                    </select>
                  </div>
                </div>
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel ">
                    Channel Marketing Manager
                  </label>
                  <div className="d-flex  ">
                    <div className="inpBox"></div>
                    <select
                      className="inputSignup"
                      id="manager"
                      onChange={informationProps.onChange}
                    >
                      <option value="">Select one--</option>
                      {marketingManager}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="formMain pt-3">
              <div className="form-row">
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel">GST Number</label>
                  <span className="optionalText pl-5">(optional)</span>
                  <div className="d-flex">
                    <div className="inpBox"></div>
                    <input
                      className="inputSignup"
                      type="text"
                      id="gstno"
                      onChange={informationProps.onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel ">Pancard Number</label>
                  <div className="d-flex  ">
                    <div className="inpBox"></div>
                    <input
                      className="inputSignup"
                      type="text"
                      id="panCardNumber"
                      onChange={informationProps.onChange}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="formMain pt-3">
              <div className="form-row">
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel">Street</label>
                  <div className="d-flex">
                    <div className="inpBox"></div>
                    <input
                      className="inputSignup"
                      type="text"
                      id="street"
                      onChange={informationProps.onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel ">City</label>
                  <div className="d-flex  ">
                    <div className="inpBox"></div>
                    <input
                      className="inputSignup"
                      type="text"
                      disabled={true}
                      value={informationProps.city}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="formMain pt-3">
              <div className="form-row">
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel">State</label>
                  <div className="d-flex">
                    <div className="inpBox"></div>
                    <select
                      className="inputSignup"
                      id="state"
                      onChange={informationProps.onChange}
                    >
                      <option value="">Select one--</option>
                      {states}
                    </select>
                  </div>
                </div>
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel ">Country</label>
                  <div className="d-flex  ">
                    <div className="inpBox"></div>
                    <select
                      className="inputSignup"
                      id="country"
                      onChange={informationProps.onChange}
                    >
                      <option value="">Select one--</option>
                      {countries}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="formMain pt-3">
              <div className="form-row">
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel">Pincode</label>
                  <div className="d-flex">
                    <div className="inpBox"></div>
                    <input
                      className="inputSignup"
                      type="text"
                      id="zipcode"
                      onChange={informationProps.onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                  <label className="pt-2 inputLabel ">Mobile Number</label>
                  <div className="d-flex  ">
                    <div className="inpBox"></div>
                    <input
                      className="inputSignup"
                      type="number"
                      id="mobileNumber"
                      onChange={informationProps.onChange}
                      onInput={(event: any) => {
                        event.target.value = event.target.value.slice(0, 10);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column"></div>
          <p className="pt-3 otpCenter otpText">
            We will send you a OTP on this mobile number.
          </p>
          <button
            className="formMain verifyNum mb-2"
            onClick={informationProps.onClick}
          >
            Verify Mobile Number
          </button>
        </div>
      </div>
    </div>
  );
};
export default Information;
