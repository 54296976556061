import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { DashboardRepository } from "../domain/repository/DashboardRepository";
import { LeadsInfo } from "../domain/model/LeadsInfo";
import moment from "moment";
export interface ILeadState {
  isLoading: boolean;
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  leads: LeadsInfo[];
  sortBy: string;
  orderBy: boolean;
  leadsError?: Error;
  filterKey: string;
  leadfilterKey: string;
  fromDate?: Date | undefined;
  toDate?: Date | undefined;
  fromDateString?: string;
  toDateString?: string;
}

export interface ILeadProps {}

export class LeadViewModel extends BaseViewModel {
  protected state: ILeadState;
  constructor(
    private baseState: BaseState,
    private dashboardRepository: DashboardRepository
  ) {
    super();
    this.state = this.defaultState();
  }

  protected setState(newState: ILeadState) {
    this.state = newState;
    this.trigger();
  }

  defaultState(): ILeadState {
    return {
      isLoading: false,
      totalCount: 0,
      pageSize: 50,
      pageIndex: 0,
      leads: [],
      sortBy: "createdDate",
      orderBy: true,
      filterKey: "All",
      leadfilterKey: "All",
      fromDate: undefined,
      toDate: undefined,
      fromDateString: "",
      toDateString: "",
    };
  }

  public async load(): Promise<void> {
    try {
      const response = await this.dashboardRepository.getLeads(
        this.state.pageIndex,
        this.state.pageSize,
        this.state.sortBy,
        this.state.orderBy,
        this.state.filterKey,
        this.state.leadfilterKey,
        this.state.fromDateString!,
        this.state.toDateString!
      );
      response.body.data.forEach((element: any) => {
        element.createdDate = moment(element.createdDate).format("D MMMM Y");
        element.dateAndTimeOfMeetingScheduled =
          element.dateAndTimeOfMeetingScheduled
            ? moment(element.dateAndTimeOfMeetingScheduled).format("D MMMM Y")
            : "";
      });
      this.setMany({
        ...this.state,
        leads: response.body.data,
        totalCount: response.body.totalCount,
      });
    } catch (error) {
      this.baseState.set("isLoading", false);
    }
  }
}
