import React from "react";
import NavigationItem from "./NavigationItem/NavigationItem";
import "./NavigationItems.css";
import ImageAssets from "../../../assets/ImageAssets";

interface NavigationItemsProps {}

const NavigationItems: React.FC<NavigationItemsProps> = (
  navigationItemsProps: NavigationItemsProps
) => {
  return (
    <div>
      <div>
        <div className="d-flex align-items-center justify-content-center">
          <img
            className="sideDcLogo"
            src={ImageAssets.dc_DcLogo_Image}
            alt="DC Logo"
          ></img>
        </div>
        <div className="mt-5">
          <NavigationItem link="/Home/" exact>
            <img src={ImageAssets.dc_MenuIcon_Image} alt="Home Logo"></img>
            <span className="pl-3">Home</span>
          </NavigationItem>
          <NavigationItem link="/Home/Leads" exact>
            <img src={ImageAssets.dc_LeadIcon_Image} alt="Home Logo"></img>
            <span className="pl-3">Leads</span>
          </NavigationItem>
          <NavigationItem link="/Home/Opportunities" exact>
            <img
              src={ImageAssets.dc_OpportunitiesIcon_Image}
              alt="Home Logo"
            ></img>
            <span className="pl-3">Opportunities</span>
          </NavigationItem>
          <NavigationItem link="/Home/Projects" exact>
            <img src={ImageAssets.dc_ProjectIcon_Image} alt="Home Logo"></img>
            <span className="pl-3">Projects</span>
          </NavigationItem>
          <NavigationItem link="/Home/Faq" exact>
            <img src={ImageAssets.dc_FaqIcon_Image} alt="Home Logo"></img>
            <span className="pl-3 ">FAQ</span>
          </NavigationItem>
        </div>
      </div>
    </div>
  );
};
export default NavigationItems;
