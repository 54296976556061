import { ComponentBase } from "resub";
import {
  ISubmitLeadState,
  SubmitLeadViewModel,
} from "../../../view-model/SubmitLeadViewModel";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import React from "react";
import "./SubmitLead.css";
import ImageAssets from "../../../assets/ImageAssets";
import { Backdrop, CircularProgress, Modal } from "@material-ui/core";
import { isNullOrUndefined } from "util";
import SnackBarComponent from "../../../components/SnackBarComponent/SnackBarComponent";

export default class SubmitLeadNew extends ComponentBase<
  any,
  ISubmitLeadState
> {
  viewModel: SubmitLeadViewModel;

  constructor(props: any) {
    super(props);
    this.viewModel = DependencyInjector.default().provideSubmitLeadViewModel();
  }

  componentDidMount(): void {
    this.viewModel.load();
  }

  onSubmitMore = () => {
    this.viewModel.setMany({
      name: "",

      lastName: "",
      mobileNumber: "",
      project_name__c: "",
      email: "",
      city: "",
      areaCode: "",
      notes: "",
      isContactedLead: false,
      isLeadSubmitedSucessfully: "",
      isLeadSubmittedDuplicate:"",
      isLoading: false,
    });
  };

  onchangeevent = (event: any) => {
    const value = event.target?.value;
    const element = event.target.id;
    if (element === "name") {
      //first name, response //lastname// lead name
      this.viewModel.set("name", value);
    } else if (element === "lastName") {
      this.viewModel.set("lastName", value);
    } else if (element === "mobileNumber") {
      this.viewModel.set("mobileNumber", value);
    } else if (element === "email") {
      this.viewModel.set("email", value);
    } else if (element === "project_name__c") {
      this.viewModel.set("project_name__c", value);
    } else if (element === "areaCode") {
      this.viewModel.set("areaCode", value);
    } else if (element === "notes") {
      this.viewModel.set("notes", value);
    } else if (element === "city") {
      this.viewModel.set("city", value);
    } else if (element === "check") {
      this.viewModel.set("isContactedLead", event.target.checked);
    }
  };

  onSubmitClicked = () => {
    this.viewModel.submitLead();
  };

  snackBarComponentClose = () => {
    this.viewModel.setMany({
      submitLeadError: undefined,
    });
  };

  render() {
    let backDropWithCircularProgress = null;
    let snackBarComponent = null;
    const regions: any = [];

    if (this.state.isLoading) {
      backDropWithCircularProgress = (
        <Backdrop open={this.state.isLoading} style={{ zIndex: 100 }}>
          <CircularProgress />
        </Backdrop>
      );
    }

    if (!isNullOrUndefined(this.state.submitLeadError)) {
      let message: string | undefined = "";
      let open = false;
      if (!isNullOrUndefined(this.state.submitLeadError)) {
        message = this.state.submitLeadError.message;
        open = true;
      }
      snackBarComponent = (
        <SnackBarComponent
          message={message}
          onClose={this.snackBarComponentClose}
          open={open}
          position={{ vertical: "bottom", horizontal: "center" }}
          severity="error"
        />
      );
    }

    if (this.state.regions) {
      for (const region of this.state.regions) {
        regions.push(
          <option key={region} value={region}>
            {region}
          </option>
        );
      }
    }

    return (
      <div className="position-relative d-flex justify-content-center">
        <div style={{ zIndex: 0 }}>
          <img
            width="100%"
            src={ImageAssets.dc_SubmitLead_Image}
            alt="Submit lead background"
          />
        </div>

        <div
          style={{
            zIndex: 1,
            position: "absolute",
            top: "50%",
            background: "#FFFFFF",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
          className="container px-0 border border-dark"
        >
          <div className="d-flex justify-content-center">
            <span className="HeadText">Lead Form</span>
          </div>

          <div className="formMain d-flex justify-content-center align-items-center flex-column pt-3">
            <div className="form-row">
              <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                <label className="pt-2 inputLabel">First Name</label>
                <span className="optionalText pl-5 ml-4">(optional)</span>
                <div className="d-flex">
                  <div className="inpBox"></div>
                  <input
                    className="inputSignup"
                    type="text"
                    id="name"
                    onChange={this.onchangeevent}
                    value={this.state.name}
                  ></input>
                </div>
              </div>
              <div className="pl-lg-3 pl-0 col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6 ">
                <label className="pt-2 inputLabel ">Last Name</label>
                <div className="d-flex ">
                  <div className="inpBox"></div>
                  <input
                    className="inputSignup"
                    type="text"
                    id="lastName"
                    onChange={this.onchangeevent}
                    value={this.state.lastName}
                  ></input>
                </div>
              </div>
            </div>
            <div className="pt-2 form-row">
              <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                <label className="pt-2 inputLabel ">Mobile Number.</label>

                <div className="d-flex pt-1">
                  <div className="inpBox"></div>
                  <input
                    className="inputSignup"
                    type="number"
                    id="mobileNumber"
                    onChange={this.onchangeevent}
                    value={this.state.mobileNumber}
                    onInput={(event: any) => {
                      event.target.value = event.target.value.slice(0, 10);
                    }}
                  ></input>
                </div>
              </div>
              <div className="pl-lg-3 pl-0 col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                <label className="pt-2 inputLabel">Email .</label>
                <span className="optionalText pl-5 ml-4">(optional)</span>
                <div className="d-flex pt-1">
                  <div className="inpBox"></div>
                  <input
                    className="inputSignup"
                    type="text"
                    id="email"
                    onChange={this.onchangeevent}
                    value={this.state.email}
                  ></input>
                </div>
              </div>
            </div>
            <div className="pt-2 form-row">
              <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                <label className="pt-2 inputLabel ">City</label>
                <div className="d-flex pt-1">
                  <div className="inpBox"></div>
                  <select
                    className="inputSignup"
                    onChange={this.onchangeevent}
                    id="city"
                    value={this.state.city}
                  >
                    <option value="">Select one--</option>
                    {regions}
                  </select>
                </div>
              </div>
              <div className="pl-lg-3 pl-0 col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                <label className="pt-2 inputLabel ">Area Code</label>
                <span className="optionalText pl-5 ml-4">(optional)</span>

                <div className="d-flex pt-1">
                  <div className="inpBox"></div>
                  <input
                    className="inputSignup"
                    type="number"
                    id="areaCode"
                    onChange={this.onchangeevent}
                    value={this.state.areaCode}
                    onInput={(event: any) => {
                      event.target.value = event.target.value.slice(0, 6);
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <div className="pt-2 form-row">
              <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                <label className="pt-2 inputLabel ">Project Name.</label>
                <div className="d-flex pt-1">
                  <div className="inpBox"></div>
                  <input
                    className="inputSignup"
                    type="text"
                    id="project_name__c"
                    onChange={this.onchangeevent}
                    value={this.state.project_name__c}
                  ></input>
                </div>
              </div>
              <div className=" hidden pl-lg-3 pl-0 col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                <label className="pt-2 inputLabel ">Area Code</label>
                <span className="optionalText pl-5 ml-4">(optional)</span>

                <div className="d-flex pt-1">
                  <div className="inpBox"></div>
                  <input
                    className="inputSignup"
                    type="text"
                    id="areaCode"
                  ></input>
                </div>
              </div>
            </div>

            <div className=" pt-1 d-none d-lg-block">
              <label className="pt-2 inputLabel  ">Notes</label>
              <span className="optionalText noteOptional">(optional)</span>
              <div className=" d-flex">
                <div className="inpBoxNote"></div>
                <textarea
                  className="noteText"
                  id="notes"
                  onChange={this.onchangeevent}
                  value={this.state.notes}
                ></textarea>
              </div>
            </div>

            <div
              style={{ marginLeft: -11 }}
              className="d-lg-none d-block col-12 px-0"
            >
              <div className="pr-8 d-flex justify-content-between col-10 pt-2 px-0">
                <label className="inputLabel ">Notes</label>
                <span className="optionalText pl-5 ml-4">(optional)</span>
              </div>

              <div className="d-flex justify-content-center">
                <div
                  style={{ height: 56, width: 2.9 }}
                  className="inpBoxNote"
                ></div>
                <textarea
                  style={{
                    resize: "none",
                    width: "83%",
                    border: "none",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
                    borderRadius: "4px",
                  }}
                  id="notes"
                  onChange={this.onchangeevent}
                  value={this.state.notes}
                ></textarea>
              </div>
            </div>

            <div className="pt-4">
              <input
                className="checkbox-terms"
                type="checkbox"
                id="check"
                onChange={this.onchangeevent}
                checked={this.state.isContactedLead}
              ></input>
              <span className="terms-text pl-2">
                I have contacted the lead personally and informed about DesignCafe.
              </span>
            </div>
            <div className="py-3 col-12 px-0 d-flex justify-content-center align-items-center flex-column ml-n5 ml-lg-0">
            { this.state.isLeadSubmittedDuplicate ? <span style={{ color:'red',fontSize: '18px'}}>Error: Duplicate Lead</span> : ''}
            <br/>
              <button
                className="w-50"
                style={{
                  background: "#069aa7",
                  boxShadow: "0px 4px 4px rgba(16, 156, 241, 0.24)",
                  borderRadius: 4,
                  color: "white",
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                  fontSize: "16px",
                  border: "none",
                  height: 48,
                }}
                onClick={this.onSubmitClicked}
              >
                Submit
              </button>
            </div>
            <Modal
              open={this.state.isLeadSubmitedSucessfully}
              onClose={this.onSubmitMore}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="blueBar"></div>
                    <div className="pt-3 d-flex justify-content-center align-items-center flex-column">
                      <img
                        src={ImageAssets.dc_SubmitLeadSuccess_Image}
                        alt="success logo"
                      ></img>
                      <p className="submitSuccess">Submitted Successfully</p>
                      <p className="submitSuccessExtra">
                        The lead has been sucessfully added
                      </p>
                      <div className="d-flex mb-4">
                        <button
                          className="doneBtn"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            this.onSubmitMore();
                            this.props.history.push("/Home");
                          }}
                        >
                          Done
                        </button>
                        <button
                          className="doneBtn ml-5"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.onSubmitMore}
                        >
                          Submit More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Modal>
          </div>
        </div>
        {backDropWithCircularProgress}
        {snackBarComponent}
      </div>
    );
  }
  protected _buildState() {
    return this.viewModel.getState();
  }
}
