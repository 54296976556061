import React from "react";
import { ComponentBase } from "resub";
import "./Faq.css";
import ImageAssets from "../../../assets/ImageAssets";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import { FaqViewModel, IFaqState } from "../../../view-model/FaqViewModel";
import { isNullOrUndefined } from "util";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

class Faq extends ComponentBase<any, IFaqState> {
  private viewModel: FaqViewModel;
  constructor(props: any) {
    super(props);
    this.viewModel = DependencyInjector.default().provideFaqViewModel();
  }

  onSearchChange = (event: any) => {
    this.viewModel.set("searchKey", event.target?.value);
    this.viewModel.load();
  };
  renderFaqs() {
    let faqComponents: any = [];
    if (!isNullOrUndefined(this.state.faqs)) {
      if (this.state.faqs.length !== 0) {
        for (let element of this.state.faqs) {
          let id = this.state.faqs.indexOf(element);
          let idString = id + "";
          let question = element.question;
          let answeComponents: any = [];
          for (let answer of element.answers) {
            let answerId = id;
            answerId++;
            let answerIdString = "" + answerId;
            answeComponents.push(<TreeItem nodeId={answerIdString} label={answer}></TreeItem>);
          }
          let questionComponent = (
            <TreeItem nodeId={idString} label={question}>
              {answeComponents}
            </TreeItem>
          );
          faqComponents.push(questionComponent);
        }
        return (
          <div className="pt-4">
            <TreeView
              className="questionText"
              defaultCollapseIcon={<ExpandLessIcon />}
              defaultExpandIcon={<ExpandMoreIcon />}
            >
              {faqComponents}{" "}
            </TreeView>
          </div>
        );
      }
    }
  }
  getAnswer(id: string, label: string) {
    return <TreeItem className="questionText" nodeId={id} label={label}></TreeItem>;
  }

  componentDidMount(): void {
    this.viewModel.load();
  }

  render() {
    return (
      <div className="pt-5">
        <div className="faqHeader d-flex justify-content-center align-items-center flex-column">
          <p className="faqTitle">Frequently Asked Questions</p>
          <div className="searchWrap pt-3">
            <img className="searchImg" src={ImageAssets.dc_Search_Image} alt="Search Logo"></img>
            <input
              placeholder="Search for your answer"
              className="searchFaq"
              onChange={this.onSearchChange}
              value={this.state.searchKey}
            />
          </div>
        </div>
        {this.renderFaqs()}
      </div>
    );
  }
  protected _buildState() {
    return this.viewModel.getState();
  }
}
export default Faq;
