import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import NavigationItems from "../../../View/containers/NavigationItems/NavigationItems";
import { Switch, Route } from "react-router";
import Leads from "../../../View/features/Leads/Leads";
import Profile from "../../../View/features/Profile/Profile";
import DashBoard from "../../../View/features/Dashboard/Dashboard";
import Opportunities from "../../../View/features/Opportunities/Opportunities";
import Faq from "../../../View/features/Faq/Faq";
import Projects from "../../../View/features/Projects/Projects";
import SubmitLead from "../../../View/features/SubmitLead/SubmitLead";
import Header from "../../../View/containers/Header/Header";
import Search from "../../../View/features/Search/Search";
import SubmitLeadNew from "../../../View/features/SubmitLead/SubmitLeadNew";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      background: "#ffffff",
      boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)",
      borderRadius: "0px 80px 0px 0px ",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      background: "#e5e5e5",
    },
  })
);

const NavBar: React.FC<any> = (navBarProps: any) => {
  const { window } = navBarProps;
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="homeMain">
      <div className="headerWrap">
        <div className="row">
          <div className="col-1 col-sm-3 col-mid-3 col-lg-3 col-xl-3">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className="col-11 col-sm-9 col-mid-9 col-lg-9 col-xl-9">
            <Header
              onClick={navBarProps.onlogoutClicked}
              onChange={navBarProps.onChange}
              user={navBarProps.logedInUser}
            />
          </div>
        </div>
      </div>
      <nav aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <NavigationItems {...navBarProps} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <NavigationItems />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className="pt-4 mainContent">
          <Switch>
            <Route
              path={`${navBarProps.match.url}/Leads`}
              component={Leads}
              exact
            />
            <Route
              path={`${navBarProps.match.url}/Opportunities`}
              component={Opportunities}
              exact
            />
            <Route
              path={`${navBarProps.match.url}/Projects`}
              component={Projects}
              exact
            />
            {/* <Route path={`${navBarProps.match.url}/submitLeads`} component={SubmitLead} exact /> */}
            <Route
              path={`${navBarProps.match.url}/submitLeads`}
              component={SubmitLeadNew}
              exact
            />
            <Route
              path={`${navBarProps.match.url}/Faq`}
              component={Faq}
              exact
            />
            <Route
              path={`${navBarProps.match.url}/Profile`}
              component={Profile}
              exact
            />
            <Route
              path={`${navBarProps.match.url}/Search`}
              component={Search}
              exact
            />
            <Route path={`${navBarProps.match.url}/`} component={DashBoard} />
          </Switch>
        </div>
      </main>
    </div>
  );
};
export default NavBar;
