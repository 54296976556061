import React from "react";
import { ComponentBase } from "resub";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import {
  IProjectState,
  ProjectViewModel,
} from "../../../view-model/ProjectViewModel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ImageAssets from "../../../assets/ImageAssets";
import "./Projects.css";
import "../../../CSS/Main.css";
import { isNullOrUndefined } from "util";
import { TablePagination, Select, MenuItem } from "@material-ui/core";
import ProjecfStageNameEnum from "../../../resources/ProjecfStageNameEnum";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export default class Projects extends ComponentBase<any, IProjectState> {
  private viewModel: ProjectViewModel;

  constructor(props: any) {
    super(props);
    this.viewModel = DependencyInjector.default().provideProjectViewModel();
  }

  componentDidMount(): void {
    if (this.props.history.location.state === null) {
      // this.viewModel.load();
      this.viewModel.set("pageIndex", 0);
      this.viewModel.set("filterKey", "All");
      console.log("in");
      this.viewModel.load();
    } else if (this.props.history.location.state === undefined) {
      this.viewModel.load();
      console.log("link");
    } else if (
      this.props.history.location.state.toDate === undefined &&
      this.props.history.location.state.fromDate === undefined &&
      this.props.history.location.state.filter !== undefined
    ) {
      this.viewModel.set("pageIndex", 0);
      this.viewModel.set("filterKey", this.props.history.location.state.filter);
      console.log(this.props.history.location.state.filter);
      this.viewModel.load();
    } else if (
      this.props.history.location.state.toDate &&
      this.props.history.location.state.fromDate !== undefined
    ) {
      console.log("else", this.props.history.location.state);
      this.viewModel.set(
        "fromDate",
        this.props.history.location.state.fromDate
      );
      this.viewModel.set(
        "fromDateString",
        moment(this.props.history.location.state.fromDate).format("YYYY-MM-DD")
      );
      //this.viewModel.load();

      this.viewModel.set("toDate", this.props.history.location.state.toDate);
      this.viewModel.set(
        "toDateString",
        moment(this.props.history.location.state.toDate).format("YYYY-MM-DD")
      );
      // this.viewModel.load();
      if (this.props.history.location.state.filter !== undefined) {
        this.viewModel.set("pageIndex", 0);
        this.viewModel.set(
          "filterKey",
          this.props.history.location.state.filter
        );
        console.log(this.props.history.location.state.filter);
        this.viewModel.load();
      } else {
        this.viewModel.load();
        console.log("date");
      }
    } else {
      this.viewModel.load();
      // this.viewModel.set("pageIndex", 0);
      // this.viewModel.set("filterKey", "All");
      console.log("out");
    }
    //this.viewModel.load();
  }

  sortInfo = (event: any) => {
    const element = event.target.id;
    this.viewModel.set("sortBy", element);
    this.viewModel.set("orderBy", !this.state.orderBy);
    this.viewModel.load();
  };
  handleFilter = (event: any) => {
    this.viewModel.set("pageIndex", 0);
    this.viewModel.set("filterKey", event.target.value);
    this.viewModel.load();
  };
  /* */
  handleFromDateChange = (date: Date) => {
    this.viewModel.set("fromDate", date);
    this.viewModel.set("fromDateString", moment(date).format("YYYY-MM-DD"));
    this.viewModel.load();
  };
  handleToDateChange = (date: Date) => {
    this.viewModel.set("toDate", date);
    this.viewModel.set("toDateString", moment(date).format("YYYY-MM-DD"));
    this.viewModel.load();
  };

  clearFromTo = () => {
    this.viewModel.set("fromDate", undefined);
    this.viewModel.set("fromDateString", "");
    this.viewModel.set("toDate", undefined);
    this.viewModel.set("toDateString", "");
    this.viewModel.load();
  };

  render() {
    console.log(this.state.projects, "projectdata");
    const tableBodyinfo: any = [];

    if (
      !isNullOrUndefined(this.state.projects) &&
      this.state.projects.length > 0
    ) {
      let index = 0;
      const projectsdata = this.state.projects?.filter(
        (e: any) => e?.projectstatus !== "Draft" && e?.projectstatus !== "draft"
      );

      for (let project of projectsdata) {
        // console.log(project, "sfdsf");
        index++;
        let statusClass = "projectStatus";
        let milestoneClass = "projectStage";
        if (project.status === "On Hold") {
          statusClass = "projectStatusOnHold";
        }
        if (project.currentmilestone === "Factory Dispatch") {
          milestoneClass = "projectStageDispatch";
        }

        if (project!.currentmilestone === "Project Signup") {
          console.log("signup");
        }

        tableBodyinfo.push(
          <TableRow key={index}>
            <TableCell className="UserName" align="justify">
              {project!.projectname}
            </TableCell>
            <TableCell className="UserName" align="justify">
              {project!.salesmanagername}
            </TableCell>
            <TableCell className="UserName" align="justify">
              {project!.signupdate}
            </TableCell>
            {/* <TableCell className="UserName" align="justify">
              {project!.projectstatus}
            </TableCell> */}

            <TableCell className="UserName" align="justify">
              {project!.totalprojectvalue}
            </TableCell>
            <TableCell className="UserName" align="justify">
              {project!.achievedrevenuevalue}
            </TableCell>
            <TableCell align="justify">{project?.currentmilestone}</TableCell>
            <TableCell align="justify">
              <div className={statusClass}>{project.projectstatus} </div>
            </TableCell>
            {/* <TableCell align="justify" className="userDetail">
              {project.projectvalue}
            </TableCell> */}
            <TableCell align="justify" className="userDetail">
              {project.endDate}
            </TableCell>
          </TableRow>
        );
      }
    }

    return (
      <div>
        <div className=" row pt-3 justify">
          <div className="">
            <button
              className="submitLeadLop"
              onClick={() => {
                this.props.history.push("/Home/submitLeads");
              }}
            >
              Submit your Lead
            </button>
          </div>
          <div className="filter">
            <div className="d-flex inherit">
              <div className=" ">
                <div className="d-flex col-sm-4">
                  <p className="pr-2 dateText">From</p>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="dateInput  ptm-2 ptm-5"
                    selected={this.state.fromDate}
                    onChange={this.handleFromDateChange}
                  />
                </div>
              </div>
              <div className="col-sm-4 col-md-0 col-lg-0 col-xl-0 ">
                <div className="d-flex">
                  <p className="pr-2 pr-4 dateText">To</p>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="dateInput ptm-2 ptm-5"
                    selected={this.state.toDate}
                    onChange={this.handleToDateChange}
                    minDate={this.state.fromDate}
                  />
                </div>
              </div>
              <div className="ptm-4 col-sm-4 col-md-0 col-lg-0 col-xl-0 pl-3 pl-sm-3 pl-md-4 pl-lg-4 pl-xl-4 pt-lg-0 pt-xl-0">
                <button
                  className="dashboard-all-time-button "
                  onClick={this.clearFromTo}
                >
                  All Time
                </button>
              </div>
            </div>
            <div className="d-flex leadDatePicker justify-content-lg-start justify-content-xl-end justify-content-md-start justify-content-sm-start justify-content-start filter1">
              <div className="d-flex  pt-2 dropdown">
                <p className="lopSort pr-1">Projects: </p>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.filterKey}
                  onChange={this.handleFilter}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value={ProjecfStageNameEnum.PROJECTSIGNUP}>
                    Project Signup
                  </MenuItem>
                  <MenuItem value={ProjecfStageNameEnum.DESIGNFINALIZATION}>
                    Design Finalization
                  </MenuItem>
                  <MenuItem value={ProjecfStageNameEnum.DESINSIGNOFF}>
                    Design Signoff
                  </MenuItem>
                  <MenuItem value={ProjecfStageNameEnum.FACTORYDISPATCH}>
                    Factory Dispatch
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <Paper>
            <TableContainer className="paper" component={Paper}>
              <Table className="paper" stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="projectname"
                      onClick={this.sortInfo}
                    >
                      Project Name{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="salesmanagername"
                      onClick={this.sortInfo}
                    >
                      Name
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="signupdate"
                      onClick={this.sortInfo}
                    >
                      Signup Date
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    {/* <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="name"
                      onClick={this.sortInfo}
                    >
                      Status
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell> */}
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="totalprojectvalue"
                      onClick={this.sortInfo}
                    >
                      Amount
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="currentmilestone"
                      onClick={this.sortInfo}
                    >
                      Collected Amount
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="currentmilestone"
                      onClick={this.sortInfo}
                    >
                      Project stage
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="projectstatus"
                      onClick={this.sortInfo}
                    >
                      Status{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    {/* <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="amount"
                      onClick={this.sortInfo}
                    >
                      Amount{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell> */}
                    {/* <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="startDate"
                      onClick={this.sortInfo}
                    >
                      Start Date{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell> */}
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="endDate"
                      onClick={this.sortInfo}
                    >
                      Tentative End Date{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableBodyinfo}</TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              labelRowsPerPage="Rows/page"
              count={this.state.totalCount}
              rowsPerPage={this.state.pageSize}
              page={this.state.pageIndex}
              onChangePage={(event, pageIndex) => {
                this.viewModel.set("pageIndex", pageIndex);
                this.viewModel.load();
              }}
              onChangeRowsPerPage={(event) => {
                console.log("pageSize", event.target.value);
                this.viewModel.set("pageSize", event.target.value);
                this.viewModel.load();
              }}
            />
          </Paper>
        </div>
      </div>
    );
  }

  protected _buildState() {
    return this.viewModel.getState();
  }
}
