import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { DashboardRepository } from "../domain/repository/DashboardRepository";

export interface IFaqState {
  isLoading: boolean;
  faqError?: Error;
  faqs: { question: string; answers: [string] }[];
  searchKey: string;
}

export interface IFaqProps {}

export class FaqViewModel extends BaseViewModel {
  protected state: IFaqState;
  constructor(
    private baseState: BaseState,
    private dashboardRepository: DashboardRepository
  ) {
    super();
    this.state = this.defaultState();
  }

  protected setState(newState: IFaqState) {
    this.state = newState;
    this.trigger();
  }

  defaultState(): IFaqState {
    return {
      isLoading: false,
      faqs: [],
      searchKey: "",
    };
  }

  public async load(): Promise<void> {
    try {
      const response = await this.dashboardRepository.getFaqs(
        this.state.searchKey!
      );
      this.setMany({
        ...this.state,
        faqs: response.body.data,
      });
    } catch (error) {
      this.baseState.set("isLoading", false);
    }
  }
}
