import React from "react";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Constants from "../../resources/Constants";
interface SnackBarComponentProps {
  open: boolean;
  message: string | undefined;
  onClose: () => void;
  position: SnackbarOrigin;
  severity: AlertProps["severity"];
  autoHideDuration?: string;
  routerProps?: any;
}

const SnackBarComponent: React.FC<SnackBarComponentProps> = (SBCProps: SnackBarComponentProps) => {
  return (
    <div>
      <Snackbar
        open={SBCProps.open}
        autoHideDuration={SBCProps.autoHideDuration ? 15000 : 3000}
        onClose={SBCProps.onClose}
        anchorOrigin={SBCProps.position}
      >
        <MuiAlert onClose={SBCProps.onClose} severity={SBCProps.severity}>
          {SBCProps.message}
          {SBCProps.routerProps && SBCProps.message === Constants.REGISTERED_USER_ERROR && <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => {
            SBCProps.routerProps.push("/Login")
          }}>Click Here To Login</span>}
        </MuiAlert>
      </Snackbar>
    </div >
  );
};

export default SnackBarComponent;
