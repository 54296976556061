export class ValidationUtils {
  public isMobileNumberValid(ph: string): boolean {
    const regX = new RegExp(/^[0-9]{10,10}$/);
    return regX.test(ph);
  }

  public isMobileNumberLengthValid(phoneNumber: string): boolean {
    return phoneNumber.length === 10;
  }

  public isEmailValid(email: string): boolean {
    const regX = new RegExp(
      /^[a-zA-Z0-9_]+(\.[_a-zA-Z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/
    );
    return regX.test(email);
  }

  public isEmpty(str: string): boolean {
    return str.length === 0 ? true : false;
  }

  public isPasswordValid(password: string): boolean {
    const regX = new RegExp(
      /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?@."]).*$/
    );
    return regX.test(password);
  }
  public isVaidGSTNumber(gstNumber: string) {
    const regX = new RegExp(
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g
    );
    return regX.test(gstNumber);
  }
  public isValidPanNumber(panNumber: string) {
    const regX = new RegExp(/[A-Z]{5}[0-9]{4}[A-Z]{1}/g);
    return regX.test(panNumber);
  }
  public isValidPinCode(pinCode:string):boolean{
    const regX=new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);
    return regX.test(pinCode);
  }
  public isValidName(name:string):boolean{
        const regX=new RegExp(/^[A-Za-z\s]*$/)                
    return regX.test(name)
  }

  public static convertToIndianStandardCurrency(text: string) {
    if (text.length > 3 && text != "null") {
      const seperatedDecimalsText = text.split(".");
      const wholeNumber = seperatedDecimalsText[0];
      const decimal = seperatedDecimalsText[1];
      var lastThree = wholeNumber.substring(wholeNumber.length - 3);
      var otherNumbers = wholeNumber.substring(0, wholeNumber.length - 3);
      if (otherNumbers !== "") lastThree = "," + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

      if (decimal) {
        res = res + "." + decimal;
      }
      return res;
    }
    if (text == "null") return 0;
    return text;
  }
}
