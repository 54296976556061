import React from "react";
import { ComponentBase } from "resub";
import {
  IOpportunityState,
  OpportunityViewModel,
} from "../../../view-model/OpportunityViewModel";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ImageAssets from "../../../assets/ImageAssets";
import "./Opportunities.css";
import "../../../CSS/Main.css";
import { isNullOrUndefined } from "util";
import { TablePagination, Select, MenuItem } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export default class Opportunities extends ComponentBase<
  any,
  IOpportunityState
> {
  private viewModel: OpportunityViewModel;

  constructor(props: any) {
    super(props);
    this.viewModel = DependencyInjector.default().provideOpportunityViewModel();
  }

  componentDidMount(): void {
    this.viewModel.load();
  }

  sortInfo = (event: any) => {
    const element = event.target.id;
    this.viewModel.set("sortBy", element);
    this.viewModel.set("orderBy", !this.state.orderBy);
    this.viewModel.load();
  };
  handleFilter = (event: any) => {
    this.viewModel.set("pageIndex", 0);
    this.viewModel.set("filterKey", event.target.value);
    this.viewModel.load();
  };
  /**/
  handleFromDateChange = (date: Date) => {
    this.viewModel.set("fromDate", date);
    this.viewModel.set("fromDateString", moment(date).format("YYYY-MM-DD"));
    this.viewModel.load();
  };
  handleToDateChange = (date: Date) => {
    this.viewModel.set("toDate", date);
    this.viewModel.set("toDateString", moment(date).format("YYYY-MM-DD"));
    this.viewModel.load();
  };

  clearFromTo = () => {
    this.viewModel.set("fromDate", undefined);
    this.viewModel.set("fromDateString", "");
    this.viewModel.set("toDate", undefined);
    this.viewModel.set("toDateString", "");
    this.viewModel.load();
  };

  render() {
    const tableBodyinfo: any = [];
    console.log(this.state.opportunities);

    if (
      !isNullOrUndefined(this.state.opportunities) &&
      this.state.opportunities.length > 0
    ) {
      let index = 0;
      for (let opportunity of this.state.opportunities) {
        index++;
        tableBodyinfo.push(
          <TableRow key={index}>
            <TableCell className="UserName" align="justify">
              {opportunity?.Name}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.Mobile__c}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.Project_Name__c}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.Amount}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.CreatedDate
                ? moment(opportunity.CreatedDate).format("YYYY-MM-DD")
                : "NA"}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.StageName}
            </TableCell>

            <TableCell className="userDetail" align="justify">
              {opportunity.CloseDate}
            </TableCell>
          </TableRow>
        );
      }
    }
    return (
      <div>
        <div className=" row pt-3 justify">
          <div className="">
            <button
              className="submitLeadLop"
              onClick={() => {
                this.props.history.push("/Home/submitLeads");
              }}
            >
              Submit your Lead
            </button>
          </div>
          <div className="filter">
            <div className="d-flex inherit">
              <div className=" ">
                <div className="d-flex col-sm-4">
                  <p className="pr-2 dateText">From</p>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="dateInput  ptm-2 ptm-5"
                    selected={this.state.fromDate}
                    onChange={this.handleFromDateChange}
                  />
                </div>
              </div>
              <div className="col-sm-4 col-md-0 col-lg-0 col-xl-0 ">
                <div className="d-flex">
                  <p className="pr-2 pr-4 dateText">To</p>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="dateInput ptm-2 ptm-5"
                    selected={this.state.toDate}
                    onChange={this.handleToDateChange}
                    minDate={this.state.fromDate}
                  />
                </div>
              </div>
              <div className="ptm-4 col-sm-4 col-md-0 col-lg-0 col-xl-0 pl-3 pl-sm-3 pl-md-4 pl-lg-4 pl-xl-4 pt-lg-0 pt-xl-0">
                <button
                  className="dashboard-all-time-button "
                  onClick={this.clearFromTo}
                >
                  All Time
                </button>
              </div>
            </div>

            <div className="d-flex leadDatePicker justify-content-lg-start justify-content-xl-end justify-content-md-start justify-content-sm-start justify-content-start filter1">
              <div className="d-flex  pt-2 dropdown">
                <p className="lopSort pr-1">Opportunities: </p>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.filterKey}
                  onChange={this.handleFilter}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Meeting Done">Meeting Done</MenuItem>
                  <MenuItem value="Proposal Sent">Proposal Sent</MenuItem>
                  <MenuItem value="Follow up">Follow up</MenuItem>
                  <MenuItem value="Discussion">Discussion</MenuItem>
                  <MenuItem value="Awaiting Closure">Awaiting Closure</MenuItem>
                  <MenuItem value="Closed Won">Closed Won</MenuItem>
                  <MenuItem value="Closed Lost">Closed Lost</MenuItem>
                </Select>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <Paper>
            <TableContainer className="paper" component={Paper}>
              <Table className="" stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="Name"
                      onClick={this.sortInfo}
                    >
                      Opportunity Name{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="Mobile__c"
                      onClick={this.sortInfo}
                    >
                      Mobile number{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="Project_Name__c"
                      onClick={this.sortInfo}
                    >
                      Project name{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="Amount"
                      onClick={this.sortInfo}
                    >
                      Project Value{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="CreatedDate"
                      onClick={this.sortInfo}
                    >
                      Meeting Done Date{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="StageName"
                      onClick={this.sortInfo}
                    >
                      Opportunity Stage{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>

                    <TableCell
                      className="userHead table-cell-single-line"
                      align="justify"
                      id="CloseDate"
                      onClick={this.sortInfo}
                    >
                      Close Date{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableBodyinfo}</TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              labelRowsPerPage="Rows/page"
              count={this.state.totalCount}
              rowsPerPage={this.state.pageSize}
              page={this.state.pageIndex}
              onChangePage={(event, pageIndex) => {
                this.viewModel.set("pageIndex", pageIndex);
                this.viewModel.load();
              }}
              onChangeRowsPerPage={(event) => {
                console.log("pageSize", event.target.value);
                this.viewModel.set("pageSize", event.target.value);
                this.viewModel.load();
              }}
            />
          </Paper>
        </div>
      </div>
    );
  }

  protected _buildState() {
    return this.viewModel.getState();
  }
}
