const ImageAssets = {
  dc_Login_Image: require("./images/Login_resize.jpg"),
  dc_Register_Image: require("./images/Signup_resize.jpg"),
  dc_SubmitLead_Image: require("./images/SubmitLead.png"),
  dc_Vector_Image: require("./images/Vector1.png"),
  dc_Prifile_Image: require("./images/Profilepic.png"),
  dc_Notification_Image: require("./images/Vector.png"),
  dc_Sort_Image: require("./images/Polygon.png"),
  dc_SubmitLeadSuccess_Image: require("./images/SubmitLeadSuccess.png"),
  dc_DcLogo_Image: require("./images/DClogo.png"),
  dc_IdCard_Image: require("./images/Id-card.png"),
  dc_Cheque_Image: require("./images/Cheque_Upload.png"),
  dc_VectorCam_Image: require("./images/Vector-cam.png"),
  dc_VectorUpload_Image: require("./images/Vector-upload.png"),
  dc_CheckMark_Image: require("./images/checkMark.png"),
  dc_MenuIcon_Image: require("./images/Menu.png"),
  dc_LeadIcon_Image: require("./images/LeadsIcon.png"),
  dc_OpportunitiesIcon_Image: require("./images/OpportunitiesIcon.png"),
  dc_ProjectIcon_Image: require("./images/ProjectIcon.png"),
  dc_FaqIcon_Image: require("./images/FaqIcon.png"),
  dc_GreenBoxImage_Image: require("./images/GreenBoxImage.png"),
  dc_GreenBoxImage2_Image: require("./images/GreenBoxImage2.png"),
  dc_RedBoxImage_Image: require("./images/RedBoxImage.png"),
  dc_RedBoxImage2_Image: require("./images/RedBoxImage2.png"),
  dc_Search_Image: require("./images/Search.png"),
  dc_SettingsIcon_Image: require("./images/SettingsIcon.png"),
  dc_SignoutIcon_Image: require("./images/SignoutIcon.png"),
};

export default ImageAssets;
