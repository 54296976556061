import { AutoSubscribeStore, autoSubscribe, StoreBase } from "resub";
import axios from "axios";
import { UserRepository } from "../domain/repository/userRepository";

export interface IBaseState {
  loggedInUser: any;
  isLoggedIn: boolean;
  isLoading: boolean;
}

@AutoSubscribeStore
export class BaseState extends StoreBase {
  protected cancellationSource: any;
  public state: IBaseState;

  constructor(private userRepository: UserRepository) {
    super();
    this.state = this.defaultState();
    this.autoAuthenticate();
  }

  autoAuthenticate = async (): Promise<void> => {
    try {
      this.set("isLoading", true);
      const response = await this.userRepository.autoAuthenticate();
      this.setMany({
        loggedInUser: response.body,
        isLoggedIn: true,
        isLoading: false,
      });
    } catch (error) {
      this.set("isLoading", false);
    }
  };

  protected createCancellationSource() {
    if (this.cancellationSource) {
      this.cancellationSource.cancel();
    }

    const CancelToken = axios.CancelToken;
    this.cancellationSource = CancelToken.source();
  }

  protected setState(newState: IBaseState) {
    this.state = newState;
    this.trigger();
  }

  @autoSubscribe
  public getState() {
    return this.state;
  }

  protected defaultState(): IBaseState {
    return {
      loggedInUser: undefined,
      isLoggedIn: false,
      isLoading: false,
    };
  }

  public reset() {
    this.setState(this.defaultState());
  }

  public set(key: string, value: any) {
    const newState: any = {
      ...this.state,
    };
    newState[key] = value;
    this.setState(newState);
  }

  public setMany(object: any) {
    const newState: any = {
      ...this.state,
    };
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        newState[key] = object[key];
      }
    }
    this.setState(newState);
  }
}
