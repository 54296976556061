import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { UserRepository } from "../domain/repository/userRepository";
import { ValidationUtils } from "../core/ValidationUtils";
import Messages from "../resources/Strings";
import Constants from "../resources/Constants";

export interface ILoginState {
  mobileNumber: string;
  isOtpGenerated: boolean;
  otp: string;
  isLoading: boolean;
  generateOtpResponse: any;
  loginVmError?: Error;
  termsAndConditionsData: string | undefined;
  page: number;
  pages: number;
  isTermsShown: boolean;
  isTermsAccepted: boolean;
}

export interface ILoginProps {
  isOpen: boolean;
  push(screnName: string): any;
  close(): any;
}

export class LoginViewModel extends BaseViewModel {
  protected state: ILoginState;
  constructor(
    private baseState: BaseState,
    private userRepository: UserRepository,
    private validationUtils: ValidationUtils
  ) {
    super();
    this.state = this.defaultState();
  }

  defaultState(): ILoginState {
    return {
      mobileNumber: "",
      isOtpGenerated: false,
      otp: "",
      isLoading: false,
      generateOtpResponse: undefined,
      termsAndConditionsData: "",
      page: 1,
      pages: 0,
      isTermsShown: true,
      isTermsAccepted: false,
    };
  }

  private validateMobileNumber(): void {
    if (this.validationUtils.isEmpty(this.state.mobileNumber)) {
      this.setMany({
        loginVmError: Error(Messages.empty_mobile_number_error),
      });
    } else if (
      !this.validationUtils.isMobileNumberLengthValid(this.state.mobileNumber)
    ) {
      this.setMany({
        loginVmError: Error(Messages.invalid_mobile_number_length_error),
      });
    }
  }

  public async generateOtp(): Promise<void> {
    this.validateMobileNumber();
    if (this.state.loginVmError) {
      return;
    }
    try {
      this.setMany({
        isLoading: true,
      });
      const data = {
        phoneNumber: this.state.mobileNumber,
      };
      const response = await this.userRepository.generateOtp(data);
      this.setMany({
        isLoading: false,
        isOtpGenerated: true,
        generateOtpResponse: response.body,
      });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
        loginVmError: error,
      });
      return;
    }
  }
  public async getTerms(): Promise<void> {
    try {
      const termsData = await this.userRepository.getTerms();
      this.setMany({
        ...this.state,
        termsAndConditionsData: termsData.body.data,
      });
    } catch (error) {
      this.baseState.set("isLoading", false);
    }
  }
  public async acceptTerms(): Promise<any> {
    try {
      const data = {
        phoneNumber: this.state.mobileNumber,
      };
      const acceptedTerms = await this.userRepository.acceptTerms(data);
      if (acceptedTerms.body.code === 200) {
        this.setMany({
          ...this.state,
          isTermsAccepted: true,
        });
      }
    } catch (error) {
      this.baseState.set("isLoading", false);
    }
  }
  private checkForEmptyOtp(): void {
    if (this.validationUtils.isEmpty(this.state.otp)) {
      this.setState({
        ...this.state,
        loginVmError: Error(Messages.empty_otp_error),
      });
    }
  }
  public async validateOtp(): Promise<void> {
    this.checkForEmptyOtp();
    if (this.state.loginVmError) {
      return;
    }
    try {
      this.setMany({
        isLoading: true,
      });
      const data = {
        phoneNumber: this.state.mobileNumber,
        otp: this.state.otp,
      };
      const response = await this.userRepository.verifyOtp(data);
      localStorage.setItem(
        Constants.LOGGED_IN_TOKEN,
        response.body.partner.loginToken
      );
      this.baseState.setMany({ loggedInUser: response.body, isLoggedIn: true });
      console.log(144, response.body.isTermsShown);

      if (response.body.isTermsShown !== true) {
        await this.getTerms();
      }
      this.setMany({
        isLoading: false,
        verifyOtpResponse: response,
        isLoginDialogOpen: false,
        isTermsShown: response.body.isTermsShown,
      });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
        loginVmError: error,
      });
    }
  }
}
