import React from "react";
import "./Header.css";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import ImageAssets from "../../../assets/ImageAssets";

interface HeaderProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  user: string;
}

const Header: React.FC<HeaderProps> = (headerProps: HeaderProps) => {
  return (
    <div>
      <div className="row">
        <div className="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8 d-flex justify-content-start">
          <img
            className="searchImgHeader"
            src={ImageAssets.dc_Search_Image}
            alt="Search Logo"
          ></img>
          <input
            className="headerSearch"
            placeholder="Search for your Lead, Opportunities and Projects"
            type="text"
            onChange={headerProps.onChange}
          />
        </div>
        <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-end pr-4 pr-sm-3 pr-md-5 pr-lg-5 pr-xl-5 nav-profile">
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState: any) => (
              <div>
                <div className="d-flex pointer" {...bindTrigger(popupState)}>
                  <div>
                    {" "}
                    <p className="profileName">{headerProps.user}</p>
                  </div>
                  <div className="popupImg">
                    <img
                      src={ImageAssets.dc_Vector_Image}
                      alt="drop down arrow"
                    ></img>
                  </div>
                </div>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box p={2} onClick={headerProps.onClick}>
                    <span className="popupUserName">{headerProps.user}</span>
                  </Box>
                  <Box p={2} onClick={headerProps.onClick}>
                    <div>
                      <img
                        src={ImageAssets.dc_SettingsIcon_Image}
                        alt="Settings Icon"
                      ></img>
                      <span id="profile" className="ml-3 popupText">
                        Profile Info
                      </span>
                    </div>
                  </Box>
                  <Box p={2} onClick={headerProps.onClick}>
                    <div>
                      <img
                        src={ImageAssets.dc_SignoutIcon_Image}
                        alt="Signout Icon"
                      ></img>
                      <span className="ml-3 popupText" id="logout">
                        Logout
                      </span>
                    </div>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </div>
      </div>
    </div>
  );
};
export default Header;
