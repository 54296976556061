import React from "react";
import { ComponentBase } from "resub";
import {
  ILoginState,
  LoginViewModel,
} from "../../../view-model/LoginViewModel";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import { BaseState } from "../../../view-model/BaseState";
import SendOtp from "../../auth/SendOtp/SendOtp";
import ValidateOtp from "../../auth/ValidateOtp/ValidateOtp";
import ImageAssets from "../../../assets/ImageAssets";
import SnackBarComponent from "../../../components/SnackBarComponent/SnackBarComponent";
import { isNullOrUndefined } from "util";
import "./Login.css";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Document, Page } from "react-pdf";

export default class Login extends ComponentBase<any, ILoginState> {
  private viewModel: LoginViewModel;
  private baseState: BaseState;
  private modalRef: any;

  constructor(props: any) {
    super(props);
    this.baseState = DependencyInjector.default().provideBaseState();
    this.viewModel = DependencyInjector.default().provideLoginViewModel();
  }
  onDocumentLoadSuccess = (e: any) => {
    this.viewModel.set("pages", e.numPages);
  };
  renderTerms() {
    return (
      <div>
        <button ref = {ref => this.modalRef = ref} type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#exampleModalCenter">
          Launch demo modal
        </button>
      <div
        className="modal bd-example-modal-lg"
        id="exampleModalCenter"
        role="dialog"
        aria-labelledby="myLargeModalLabel1"
        aria-hidden="true"
        data-show={true}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel1">
                <div className="termsText">Terms and Conditions</div>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.viewModel.set("page", 1,);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Document
                className="text-transform-none"
                file={
                  `data:application/pdf;base64,${this.state.termsAndConditionsData}`
                }
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={this.state.page} />
                <div className="col-12 px-0 d-flex justify-content-center align-items-center">
                  <button
                    className="document-pdf-viewer-next-and-prev-button"
                    onClick={() => {
                      this.state.page - 1 > 0 &&
                        this.viewModel.set("page", this.state.page - 1);
                    }}
                  >
                    {"<"}
                  </button>
                  <p className="pt-3 px-3 document-pdf-viewer-page-count">
                    Page {this.state.page} of {this.state.pages}
                  </p>
                  <button
                    className="document-pdf-viewer-next-and-prev-button"
                    onClick={() => {
                      this.state.page < this.state.pages &&
                        this.viewModel.set("page", this.state.page + 1);
                    }}
                  >
                    {">"}
                  </button>
                </div>
              </Document>
              <div style={{ textAlign: "center" }}>
                <button
                  className="submitBtn"
                  data-dismiss="modal"
                  aria-label="Close"
                  disabled={
                    this.state.page === this.state.pages
                      ? false
                      : true
                  }
                  style={{
                    opacity: `${
                      this.state.page === this.state.pages ? 1 : 0.5
                    }`,
                  }}
                  onClick={async (event: any) => {
                    if (this.state.page === this.state.pages) {
                      this.viewModel.set(
                        "page", 1,
                      )
                      await this.viewModel.acceptTerms()
                      if(this.state.isTermsAccepted) {
                        if (this.baseState.state.isLoggedIn) {
                          this.props.history.push("/Home");
                        }
                      }
                    }
                  }}
                >
                  I Agree and Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      )
  }

  sendOtpClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.viewModel.generateOtp();
  };

  mobileNumberChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel.set("mobileNumber", event.target.value);
  };

  validateOtpHandler = async(event: React.MouseEvent<HTMLButtonElement>) => {
    await this.viewModel.validateOtp();
    if (!this.state.loginVmError) {
      if(!this.state.isTermsShown) {
        this.modalRef?.click()
      }else {
        this.props.history.push("/Home");
      }
    }
  };

  otpChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.viewModel.set("otp", event.target.value);
  };

  // componentDidUpdate() {
  //   if (this.state.isTermsShown && this.baseState.state.isLoggedIn) {
  //     this.props.history.push("/Home");
  //   }
  // }

  render() {
    let snackBarComponent = null;
    let backDropWithCircularProgress = null;
    let sendOrValidateOtp = (
      <SendOtp
        onClick={this.sendOtpClicked}
        onChange={this.mobileNumberChangeHandler}
      />
    );
    let message: string | undefined = "";
    let open = false;
    if (!isNullOrUndefined(this.state.loginVmError)) {
      message = this.state.loginVmError.message;
      open = true;
      snackBarComponent = (
        <SnackBarComponent
          message={message}
          onClose={() => {
            this.viewModel.setMany({ loginVmError: undefined });
          }}
          open={open}
          position={{ vertical: "bottom", horizontal: "center" }}
          severity="error"
        />
      );
    }

    if (this.state.isOtpGenerated) {
      sendOrValidateOtp = (
        <ValidateOtp
          onClick={this.validateOtpHandler}
          onChange={this.otpChangeHandler}
          resendOtp={this.sendOtpClicked}
          model={this.viewModel}
        />
      );
    }

    if (this.state.isLoading) {
      backDropWithCircularProgress = (
        <Backdrop open={this.state.isLoading}>
          <CircularProgress />
        </Backdrop>
      );
    }
    return (
      <div>
        {this.state.generateOtpResponse !== undefined && (
          <SnackBarComponent
            message={
              this.state.generateOtpResponse &&
              this.state.generateOtpResponse.message
            }
            onClose={() => {
              this.viewModel.set("generateOtpResponse", undefined);
            }}
            open={this.state.generateOtpResponse !== undefined ? true : false}
            position={{ vertical: "bottom", horizontal: "center" }}
            severity="success"
            autoHideDuration="15000"
            routerProps={this.props.history}
          />
        )}

        <div className="backDrop">{backDropWithCircularProgress}</div>
        <div className="row ml-0 mr-0">
          <div className="col-0 col-sm-0 col-mid-0 col-lg-0 col-xl-7 pl-0">
            <img
              className="loginImg"
              src={ImageAssets.dc_Login_Image}
              alt="LoginImage"
            />
          </div>
          <div className="col-0 col-sm-0 col-mid-0 col-lg-0 col-xl-5 no-gutters ">
            <div>{sendOrValidateOtp}</div>
          </div>
          {snackBarComponent}
        </div>
        {this.renderTerms()}
      </div>
    );
  }

  protected _buildState() {
    return this.viewModel.getState();
  }
}
