import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { DashboardRepository } from "../domain/repository/DashboardRepository";
import { ValidationUtils } from "../core/ValidationUtils";
import { LeadsInfo } from "../domain/model/LeadsInfo";

export interface IDashboardState {
  isLoading: boolean;
  dashboardError?: Error;
  fromDate?: Date | undefined;
  toDate?: Date | undefined;
  fromDateString?: string;
  toDateString?: string;

  chartData?: { [k: string]: any };
  dashboardData: { [k: string]: any };
}

export interface IDashboardProps {}

export class DashboardViewModel extends BaseViewModel {
  protected state: IDashboardState;
  constructor(
    private baseState: BaseState,
    private dashboardRepository: DashboardRepository
  ) {
    super();
    this.state = this.defaultState();
  }

  protected setState(newState: IDashboardState) {
    this.state = newState;
    this.trigger();
  }

  defaultState(): IDashboardState {
    return {
      isLoading: false,
      fromDate: undefined,
      toDate: undefined,
      fromDateString: "",
      toDateString: "",
      chartData: {},
      dashboardData: {},
    };
  }

  public async load(): Promise<void> {
    try {
      const response = await this.dashboardRepository.getDashboardData(
        this.state.fromDateString!,
        this.state.toDateString!
      );

      response.body.dashboardData.commissionsPaid =
        ValidationUtils.convertToIndianStandardCurrency(
          response.body.dashboardData.commissionsPaid + ""
        );

      response.body.dashboardData.commissionsDue =
        ValidationUtils.convertToIndianStandardCurrency(
          response.body.dashboardData.commissionsDue + ""
        );

      response.body.dashboardData.gstAmount =
        ValidationUtils.convertToIndianStandardCurrency(
          response.body?.dashboardData?.gstAmount || 0
        );

      // if ()
      this.setMany({
        ...this.state,
        leads: response.body?.data || {},
        totalCount: response?.body?.totalCount || 0,
        dashboardData: response?.body?.dashboardData || {},
        chartData: response?.body?.chartData || {},
      });
    } catch (error) {
      this.baseState.set("isLoading", false);
    }
  }
}
