import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { DashboardRepository } from "../domain/repository/DashboardRepository";
import { OpportunityInfo } from "../domain/model/OpportunityInfo";
import moment from "moment";
import { ValidationUtils } from "../core/ValidationUtils";
export interface IOpportunityState {
  isLoading: boolean;
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  sortBy: string;
  orderBy: boolean;
  opportunities: OpportunityInfo[];
  filterKey: string;
  fromDate?: Date | undefined;
  toDate?: Date | undefined;
  fromDateString?: string;
  toDateString?: string;
}

export interface IProjectProps {}

export class OpportunityViewModel extends BaseViewModel {
  protected state: IOpportunityState;
  constructor(
    private baseState: BaseState,
    private dashboardRepository: DashboardRepository
  ) {
    super();
    this.state = this.defaultState();
  }

  protected setState(newState: IOpportunityState) {
    this.state = newState;
    this.trigger();
  }

  defaultState(): IOpportunityState {
    return {
      isLoading: false,
      totalCount: 0,
      pageSize: 25,
      pageIndex: 0,
      sortBy: "createdDate",
      orderBy: true,
      opportunities: [],
      filterKey: "All",
      fromDate: undefined,
      toDate: undefined,
      fromDateString: "",
      toDateString: "",
    };
  }

  public async load(): Promise<void> {
    try {
      const response = await this.dashboardRepository.getOpportunity(
        this.state.pageIndex,
        this.state.pageSize,
        this.state.sortBy,
        this.state.orderBy,
        this.state.filterKey,
        this.state.fromDateString!,
        this.state.toDateString!
      );
      response.body.data.forEach((element: any) => {
        element.amount = ValidationUtils.convertToIndianStandardCurrency(
          "" + element.amount
        );
        element.createdDate = moment(element.createdDate).format("D MMMM Y");
        element.closeDate = moment(element.closeDate).format("D MMM Y");
      });

      this.setMany({
        ...this.state,
        opportunities: response.body.data,
        totalCount: response.body.totalCount,
      });
    } catch (error) {
      this.baseState.set("isLoading", false);
    }
  }
}
