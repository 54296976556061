import React, { RefObject } from "react";
import ImageAssets from "../../../assets/ImageAssets";
import ".././Verification/Verification.css";
import ".././Information/Information.css";
import "../.././auth/SendOtp/SendOtp.css";
import "./Documents.css";
import SnackBarComponent from "../../../components/SnackBarComponent/SnackBarComponent";
import Strings from "../../../resources/Strings";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface DocumentsProps {
  pdfData: string | undefined;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTermsAccepted: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isPanCardUploaded: boolean;
  isAddressUploaded?: boolean;
  isCancelledChequeUploaded?: boolean;
  checkboxInsideModel: boolean;
  model: any;
}

class Documents extends React.Component<DocumentsProps, any> {
  private readonly panCardFileRef: RefObject<HTMLInputElement>;
  private readonly cancelledChequeFileRef: RefObject<HTMLInputElement>;
  private readonly addressProofFileRef: RefObject<HTMLInputElement>;
  private error?: Error = undefined;
  constructor(props: DocumentsProps) {
    super(props, {});
    this.panCardFileRef = React.createRef();
    this.cancelledChequeFileRef = React.createRef();
    this.addressProofFileRef = React.createRef();
  }

  state = {
    page: 1,
    pages: 0,
  };

  onDocumentLoadSuccess = (e: any) => {
    this.setState({
      pages: e.numPages,
    });
  };

  panCardFileHandler = () => {
    this.panCardFileRef.current?.click();
  };

  cancelledChequeHandler = () => {
    this.cancelledChequeFileRef.current?.click();
  };

  addressProofHandler = () => {
    this.addressProofFileRef.current?.click();
  };

  checkForImages = (
    isSubmitButtonClicked: boolean,
    isCheckboxSeleted: boolean
  ) => {
    if (isSubmitButtonClicked && !this.props.isPanCardUploaded) {
      this.props.model.set(
        "documentImagesError",
        Error("Please upload pan card image")
      );
      return;
    } else if (isSubmitButtonClicked && !this.props.isCancelledChequeUploaded) {
      this.props.model.set(
        "documentImagesError",
        Error("Please upload cancelled cheque image")
      );
      return;
    } else if (isSubmitButtonClicked && !this.props.isAddressUploaded) {
      this.props.model.set(
        "documentImagesError",
        Error("Please upload address proof image")
      );
      return;
    } else if (isSubmitButtonClicked && !isCheckboxSeleted) {
      this.props.model.set(
        "documentImagesError",
        Error(Strings.select_is_not_dc_employee_error)
      );
      return;
    }
  };

  render() {
    let isSubmitButtonClicked = false;
    let isCheckboxSeleted = false;
    return (
      <div className=" d-flex align-items-center justify-content-center flex-column">
        <div className="container d-flex align-items-center justify-content-center flex-column">
          <div className="cardSignupDoc">
            <div className="d-flex align-items-center justify-content-center flex-column container1">
              <div className="pt-3 pt-sm-3 pt-md-3 pt-lg-5 pt-xl-5">
                <img
                  className=""
                  src={ImageAssets.dc_DcLogo_Image}
                  alt="DC Logo"
                ></img>
              </div>
              <p className="loginText pt-3">Become our Patner</p>
              <div className="">
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <p className="signupText-blur">Personal Information</p>
                    <div className="infoBar-blur"></div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <p className="signupText">Documents</p>
                    <div className="infoBar"></div>
                  </div>
                </div>
              </div>
              <div className="pt-5 formMainDoc">
                <div className="row">
                  <div
                    className="col-0 col-sm-0 col-md-0 col-lg-4 col-xl-4 pb-2 pb-sm-2 pb-md-2 pb-lg-0 pb-xl-0 pb-2 pb-sm-2 pb-md-2 pb-lg-0 pb-xl-0 "
                    onClick={this.panCardFileHandler}
                  >
                    <div className="docBox d-flex align-items-center justify-content-center flex-column">
                      {this.props.isPanCardUploaded ? (
                        <img
                          width="80"
                          height="80"
                          src={ImageAssets.dc_CheckMark_Image}
                          alt="Check Mark Logo"
                        ></img>
                      ) : (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                          <img
                            src={ImageAssets.dc_IdCard_Image}
                            alt="ID Card Logo"
                          ></img>
                          <p className="docBox-text pt-1">Pan Card</p>
                          <input
                            type="file"
                            onChange={this.props.onChange}
                            ref={this.panCardFileRef}
                            style={{ display: "none" }}
                            id="pancard"
                            // accept="image/*"
                          />
                          <div className="d-flex">
                            <div className="docBox-small d-flex pl-1">
                              <img
                                className="docBox-img"
                                src={ImageAssets.dc_VectorCam_Image}
                                alt="Camara Logo"
                              ></img>
                              <span className="docBox-small-text"> Camera</span>
                            </div>
                            <div className="docBox-small d-flex pl-1">
                              <img
                                className="docBox-img"
                                src={ImageAssets.dc_VectorUpload_Image}
                                alt="Upload Logo"
                              ></img>
                              <span className="docBox-small-text"> Upload</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-0 col-sm-0 col-md-0 col-lg-4 col-xl-4 pb-2 pb-sm-2 pb-md-2 pb-lg-0 pb-xl-0"
                    onClick={this.cancelledChequeHandler}
                  >
                    <div className="docBox d-flex align-items-center justify-content-center flex-column">
                      {this.props.isCancelledChequeUploaded ? (
                        <img
                          width="80"
                          height="80"
                          src={ImageAssets.dc_CheckMark_Image}
                          alt="Check Mark Logo"
                        ></img>
                      ) : (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                          <img
                            src={ImageAssets.dc_Cheque_Image}
                            alt="Cheque Logo"
                          ></img>
                          <p className="docBox-text pt-1">Cancelled Cheque</p>
                          <input
                            type="file"
                            ref={this.cancelledChequeFileRef}
                            style={{ display: "none" }}
                            onChange={this.props.onChange}
                            id="cancelledCheque"
                            // accept="image/*"
                          />
                          <div className="d-flex">
                            <div className="docBox-small d-flex pl-1">
                              <img
                                className="docBox-img"
                                src={ImageAssets.dc_VectorCam_Image}
                                alt="Camara Logo"
                              ></img>
                              <span className="docBox-small-text"> Camera</span>
                            </div>
                            <div className="docBox-small d-flex pl-1">
                              <img
                                className="docBox-img"
                                src={ImageAssets.dc_VectorUpload_Image}
                                alt="Upload Logo"
                              ></img>
                              <span className="docBox-small-text"> Upload</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-0 col-sm-0 col-md-0 col-lg-4 col-xl-4 pb-2 pb-sm-2 pb-md-2 pb-lg-0 pb-xl-0"
                    onClick={this.addressProofHandler}
                  >
                    <div className="docBox d-flex align-items-center justify-content-center flex-column">
                      {this.props.isAddressUploaded ? (
                        <img
                          width="80"
                          height="80"
                          src={ImageAssets.dc_CheckMark_Image}
                          alt="Check Mark Logo"
                        ></img>
                      ) : (
                        <div className="d-flex align-items-center justify-content-center flex-column">
                          <img
                            src={ImageAssets.dc_IdCard_Image}
                            alt="ID Card Logo"
                          ></img>
                          <p className="docBox-text pt-1">Address Proof</p>
                          <input
                            type="file"
                            ref={this.addressProofFileRef}
                            style={{ display: "none" }}
                            onChange={this.props.onChange}
                            id="addressProof"
                            // accept="image/*"
                          />
                          <div className="d-flex">
                            <div className="docBox-small d-flex pl-1">
                              <img
                                className="docBox-img"
                                src={ImageAssets.dc_VectorCam_Image}
                                alt="Camara Logo"
                              ></img>
                              <span className="docBox-small-text"> Camera</span>
                            </div>
                            <div className="docBox-small d-flex pl-1">
                              <img
                                className="docBox-img"
                                src={ImageAssets.dc_VectorUpload_Image}
                                alt="Upload Logo"
                              ></img>
                              <span className="docBox-small-text"> Upload</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <p className="upload-text pt-3">
                (Upload should be less than 5MB)
              </p>
              {/* <div className="pt-3 pr-0 pr-sm-0 pr-md-0 pr-xl-5 pr-lg-5 termsMain">
                <span className="terms-text">
                  <span
                    className="terms-text-extra"
                    
                  >
                    {" "}
                    Please click here to accept the terms and conditions of
                    Design Cafe{" "}
                  </span>
                </span>
              </div> */}
              <div className="pr-0 pr-sm-0 pr-md-0 pr-xl-5 pr-lg-5 pl-0 pl-sm-0 pl-md-0 pl-xl-5 pl-lg-4">
                <input
                  className="checkbox-terms"
                  type="checkbox"
                  onChange={(event: any) => {
                    this.props.onChange(event);
                    isCheckboxSeleted = !isCheckboxSeleted;
                    this.props.model.set(
                      "shouldShowModel",
                      !this.props.model.state.shouldShowModel
                    );
                  }}
                  id="isNotEmployeeOfDesignCafeChecked"
                ></input>
                <span className="terms-text">
                  I declare that I am not related to any of the employee at the
                  DesignCafe .
                </span>
              </div>
              <div className="pt-3"></div>
              {/* onClick={this.props.onClick} */}
              <button
                className="submitBtn"
                data-toggle="modal"
                data-target=".bd-example-modal-lg"
                onClick={() => {
                  isSubmitButtonClicked = true;
                  this.checkForImages(isSubmitButtonClicked, isCheckboxSeleted);
                }}
              >
                Submit
              </button>

              <SnackBarComponent
                message={
                  this.props.model.state.documentImagesError &&
                  this.props.model.state.documentImagesError.message
                }
                onClose={() => {
                  this.props.model.set("documentImagesError", undefined);
                }}
                open={
                  this.props.model.state.documentImagesError !== undefined
                    ? true
                    : false
                }
                position={{ vertical: "bottom", horizontal: "center" }}
                severity="error"
                autoHideDuration="15000"
              />
            </div>
            {this.props.isPanCardUploaded &&
              this.props.isCancelledChequeUploaded &&
              this.props.isAddressUploaded &&
              this.props.model.state.shouldShowModel && (
                <div
                  className="modal fade bd-example-modal-lg"
                  id="exampleModal"
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                  data-show={this.props.model.state.shouldShowModel}
                >
                  <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header modal-head ">
                        <h5 className="modal-title" id="exampleModalLabel">
                          <div className="termsText">Terms and Conditions</div>
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body" style={{ height: '90%'}}>
                        <div className="bodyTnC">
                          <p style={{ textAlign: "center", fontSize: "30 px" }}>
                            <b>Terms of Use-Channel Partner</b>
                          </p>
                          <p>
                            These terms of use (“<b>Terms-CL</b>”) are an
                            electronic record in terms of Information Technology
                            Act, 2000 (“<b>ITAct, 2000</b>”),the applicable
                            rules there under and the provisions pertaining
                            to electronic records in various statutes as amended
                            by the IT Act, 2000. This electronic record is
                            generated by a computer system and does not require
                            any physical or digital signatures.
                          </p>
                          <p>
                            This document is published in accordance with the
                            provisions of Rule 3(1)of the Information Technology
                            (Intermediaries Guidelines) Rules, 2011 that require
                            publishing the rules and regulations, privacy policy
                            and terms of use for access to or usage of 
                            <a href="www.designcafe.com"> www.designcafe.com </a>
                             and associated sub domains (“<b>OurWebsite</b>”).
                          </p>
                          <p>
                            These Terms-CL (along with the Addendum A provided
                            below) constitute a binding agreement between
                            <b> WE/US/COMPANY/DesignCafé</b> AND
                            <b> YOU/YOUR/CHANNELPARTNER.</b>
                            Further, our terms of Use available at
                            <a href="www.designcafe.com"> www.designcafe.com </a>
                            and our privacy policy available at
                            <a href="www.designcafe.com"> www.designcafe.com </a>
                            are an integral part of these Terms-CL.
                          </p>
                          <p>
                            For the purposes of clarity, the term
                            <b> COMPANY/US/ OUR/DesignCafé</b> shall include
                            holding company, subsidiary
                            companies, successors-in-interest, executors,
                            permitted assigns, affiliates, associates and group
                            companies and the term <b>CHANNEL PARTNER/YOU </b>
                            shall include its employees, directors, service
                            providers, representatives, agents, associates and
                            successors.
                          </p>
                          <p>
                            Once you have accepted these Terms-CL, you shall
                            provide services (as provided in these Terms-CL)
                            including but not limited to marketingcand promotion
                            of products and services offered by us to
                            potential customers within the Territory as per the
                            terms contained herein.
                          </p>
                          <p>
                            In the event, we wish to change these Terms-CL we
                            will seek your express consent vide the email id
                            and/or the contact information provided by you at
                            the time of registration. However, if You fail to
                            respond to the notification within the reasonable
                            prescribed time as provided in the notification and
                            continue to provide Services and use Our Website
                            following the changes or updates to these Terms-CL,
                            it will have deemed that You accept and agree to the
                            revisions. If at point of time you do not agree to
                            with the updated these Terms-CL or wish to terminate
                            this agreement, you must stop immediately and write
                            an email to 
                            <a href="mailto:harsh.gangwal@designcafe.com">
                               harsh.gangwal@designcafe.com
                            </a>
                            , and these Terms-CL will be terminated subject to
                            the terms contained herein please note that this
                            does not in any manner affect our right to change,
                            modify, add, or remove portions of our Terms of Use
                            and Privacy Policy time. Please take the time to
                            periodically review the Terms of Use and Privacy
                            Policy for the latest information on our practices.
                          </p>
                          <br />
                          <p style={{ textAlign: "center", fontSize: "25 px" }}>
                            <b>MAINCOMMERCIAL TERMS</b>
                          </p>
                          <br />
                          <table className="tncTable">
                            <tr>
                              <th className="tncTable" >Term</th>
                              <td className="tncTable">
                                This agreement shall commence on the from the
                                date you accept these Terms-CL and shall stand
                                auto renewed on the same terms and conditions as
                                on March 31 succeeding the date of acceptance
                                of this Terms-CL every year, unless terminated
                                earlier in accordance with Clause 5 of the
                                Addendum A.
                              </td>
                            </tr>
                            <tr>
                              <th className="tncTable">Services</th>
                              <td className="tncTable">
                                The channel partner shall share the lead
                                information with DesignCafé.
                              </td>
                            </tr>
                            <tr>
                              <th className="tncTable">Territory</th>
                              <td className="tncTable">Bangalore, Mumbai and Hyderabad</td>
                            </tr>
                            <tr>
                              <th className="tncTable">Commission</th>
                              <td className="tncTable">
                                <ol>
                                  <li>
                                    The Channel Partner shall be entitled to a
                                    referral fee of 4% on the total sale amount
                                    plus a yearly bonus.
                                  </li>
                                  <li>
                                    The referral fee shall be paid in 2 (two)
                                    instalments:
                                  </li>
                                  <ol type="i">
                                    <li>
                                      The Channel Partner shall be paid an
                                      initial referral fee of 2% when DesignCafe receives the sign up / booking amount
                                      from the customer; and
                                    </li>
                                    <li>
                                      2% or the balance of the referral fee
                                      shall be paid by DesignCafe within 30
                                      (Thirty) days from receipt of 100% of the
                                      order value from the customer and on
                                      receiving an appropriate invoice for the
                                      same from the Channel Partner.
                                    </li>
                                  </ol>
                                  <li>
                                    Any other statutory levies and taxes,
                                    including service tax, will be extra.
                                  </li>
                                  <li>
                                    The Channel Partner hereby accepts and
                                    acknowledges the sufficiency of the
                                    consideration payable to him and further
                                    accepts that it shall not be entitled to any
                                    other consideration including but not limited
                                    to any reimbursements.
                                  </li>
                                  <li>
                                    The Channel Partner hereby accepts and
                                    acknowledges the sufficiency of the
                                    consideration payable to him and further
                                    accepts that it shall not be entitled to any
                                    other consideration including but not
                                    limited to any reimbursements.
                                  </li>
                                  <ol type="i">
                                    <li>
                                      0.25% if the total sales are more than 25
                                      Lacs in a fiscal year
                                    </li>
                                    <li>
                                      0.50% if the total sales are more than 50
                                      Lacs in a fiscal year
                                    </li>
                                    <li>
                                      1.00% if the total sales are more than 1
                                      crores in a fiscal year,
                                    </li>
                                    <li>
                                      5 Lacs and an Omega Seamaster worth 4 lacs
                                      if the total sales are more than 5
                                      crores in a fiscal year
                                    </li>
                                  </ol>
                                  <li>
                                    The Yearly Bonus may vary and DesignCafe
                                    reserves the right to change it
                                    without receiving the consent of Channel
                                    Partner.
                                  </li>
                                </ol>
                              </td>
                            </tr>
                          </table>
                          <br />
                          <p style={{ textAlign: "center", fontSize: "25 px" }}>
                            <b>ADDENDUM A</b>
                          </p>
                          <p style={{ textAlign: "center", fontSize: "25 px" }}>
                            <b>GENERAL TERMS AND CONDITIONS</b>
                          </p>
                          <p>
                            DesignCafé and the Channel Partner shall herein
                            after, wherever the context so requires, be
                            individually referred to as a “Party” and
                            collectively as the “Parties”.
                          </p>
                          <br />
                          <ol>
                            <li>
                              <b> TERMS OF ENGAGEMENT</b>
                            </li>
                            <ol>
                              <li>
                                Whereby engage You as the Channel Partner for
                                the purposes of availing the marketing and selling
                                its business related
                                services(“Services”) including but not limited to
                                marketing and promotion of products and services
                                offered by us to potential customers within
                                the Territory, including any new territory
                                additions by us within the term of this
                                engagement.
                              </li>
                              <li>
                                You shall submit regular information to Us with
                                respect to customer leads relevant to Us and all
                                information as requested by Us through its
                                salesforce forms or share such information with
                                our designated channel marketing manager. In the
                                event, We already have such customer leads in its
                                database, then the same shall be counted as null
                                and void.
                              </li>
                              <li>
                                You shall comply with all applicable laws related
                                to the Services rendered under these terms. At no
                                point, You shall represent yourself as the owner
                                or manufacturer of the product or
                                services proposes to be provided by Us or in any
                                manner except as expressly permitted under these
                                terms, and shall be required to inform
                                any potential customer of the same.
                              </li>
                              <li>
                                You shall immediately and promptly notify Us of
                                any complaints, queries or feedback received from
                                any customer.
                              </li>
                              <li>
                                You shall have full control and authority over
                                the means of performing the Services. Provided,
                                such means is not in conflict with Our business
                                interests. Our determination as to what
                                constitutes 'conflict of interest' shall be
                                final and binding.
                              </li>
                              <li>
                                We shall provide You with promotional materials
                                on a regular basis, which you may share with the
                                customers. All marketing collaterals including but
                                not limited to brochures, catalogues and power
                                pointsshall have to be pre-approved by Design
                                Cafe and shall clearly display DesignCafe
                                branding.
                              </li>
                              <li>
                                It is hereby agreed between the Parties that the
                                discounts and costing given to each customeris
                                at Our discretion and shall be as per Our
                                policies.
                              </li>
                              <li>
                                You hereby accept and acknowledge the sufficiency
                                of the consideration payable and further accept
                                that You shall not be entitled to any other
                                consideration including but not limited to any
                                reimbursements.
                              </li>
                              <li>
                                At no point during the term of the Terms-CL,
                                you shall engage in advertising or solicitation
                                of businesses competing with Our business,
                                whether directly or indirectly
                              </li>
                            </ol>
                            <li>INDEMNITY AND LIABILITY</li>
                            <ol>
                              <li>
                                You shall indemnify or otherwise defend Us from
                                and against any and alllosses, damages, costs
                                (including reasonable attorney fees), claims,
                                 suits or actions that may arise due to the your
                                acts or omissions including but not limited to
                                unauthorised use or disclosure of any
                                confidential information, any
                                misrepresentations or any unauthorised use of the
                                products and or services, breach of intellectual
                                property (including any third party’s
                                intellectual property), or breach of terms
                                contained herein.
                              </li>
                              <li>
                                Under no circumstances shall We be liable for
                                any loss of actual or anticipated revenues,
                                profits, goodwill, opportunity or business or for
                                any consequential, special, incidental,
                                exemplary, punitive or other indirect losses or
                                damages, whether arising out of or related to
                                these terms including those in contract, tort
                                (including negligence) or otherwise, even if such
                                loss was foreseeable or if We have been advised of
                                the possibility of such damages or loss.
                              </li>
                              <li>
                                Neither party shall be liable for
                                non-performance of their respective duties and
                                obligations under these terms if such
                                non-performance was a direct result of a Force
                                Majeure event.
                              </li>
                            </ol>
                            <p>
                              For the purposes of these terms, a Force Majeure
                              event shall mean any event beyond the control of
                              the Parties including but not limited to Acts of
                              God (including drought, floods, earthquakes, and
                              cyclones), war, riots or other unforeseeable events
                              which prevents any Party from fulfilling any of its
                              obligations under these terms. In the event
                              the Force Majeure event continues beyond 1 (one)
                              month, we may terminate the Terms-CL, subject to
                              clearing all dues payable to You till the date of
                              notification of the Force Majeure event.
                            </p>
                            <li>DATA PRIVACY</li>
                            <ol>
                              <li>
                                It shall be your responsibility to obtain prior
                                written consent from their customers with respect
                                to the personal data of such customers which is
                                shared by You Partner with us for the purposes
                                of these terms. It shall be the obligation of the
                                Channel Partner to comply with any and all
                                applicable data privacy laws while sharing its
                                customer's data with us.
                              </li>
                            </ol>
                            <li>
                              <b>INTELLECTUAL PROPERTY</b>
                            </li>
                            <ol>
                              <li>
                                We hereby grant You a limited, non-exclusive
                                right to use Our trademarks, trade names, logos,
                                and icons for the purposes of rendering
                                the Services, subject to our prior written
                                consent.
                              </li>
                              <li>
                                All our material and information, including
                                images, illustrations, audio clips, and video
                                clips on Our website, are protected
                                by copyrights, trademarks and other intellectual
                                property rights. You undertake not to copy,
                                reproduce, republish, upload, post, transmit, or
                                distribute Our material in any way, including by
                                email or other electronic means and whether,
                                directly or indirectly, or assist any other
                                person to do so. Without the prior written
                                consent of the owner, modification or use of the
                                material on any other website/networked computer
                                environment or for any purpose other than
                                personal, non-commercial use is a violation of
                                the copyrights, trademarks and other proprietary
                                rights is prohibited.
                              </li>
                              <li>
                                You hereby and shall assign in Our favour any
                                and all intellectual property
                                discovered,developed or improved upon by it
                                during the course of these terms or otherwise
                                discovered, developed or improved upon with
                                any proprietary information or material obtained
                                from Us
                              </li>
                              <li>
                                You shall immediately notify us in the event it
                                identifies or is notified that any third party
                                product is infringing upon the our
                                intellectual property.
                              </li>
                            </ol>
                            <li>
                              <b>RELATIONSHIP OF THE PARTIES</b>
                            </li>
                            <ol>
                              <li>
                                Nothing contained herein shall be construed as
                                creating any relation of principal agent,
                                employee-employer between the Parties. Each
                                Party shall be solely responsible for all matters
                                relating to its employees, agents, subsidiaries
                                and subcontractors including any tax or labour
                                requirements. The Parties further agree not to
                                make any false or misleading claims or
                                representations regarding the other Party, the
                                Products or with regard to the relation
                                between the Parties.
                              </li>
                            </ol>
                            <li>
                              <b>MISCELLANEOUS</b>
                            </li>
                            <ol>
                              <li>
                                We reserve the right to enter into a similar
                                agreement with other individuals or entities
                                providing services similar to that of the Channel
                                Partner. Similarly, the Channel Partner can
                                enter into a similar agreement with any other
                                firm/corporate/body.
                              </li>
                              <li>
                                You hereby represent and warrants that all of
                                the information provided by it to Us is true,
                                accurate and complete to the best of
                                its knowledge and that it has all requisite skill
                                and expertise to perform the Services.
                              </li>
                              <li>
                                We make no representations or warranties of any
                                manner with regard to the merchantability or
                                fitness of the Products and hereby excludes all
                                representations and warranties permitted under
                                law.
                              </li>
                              <li>
                                Display of information online or offline shall
                                be only after our written approval
                              </li>
                              <li>
                                These terms and conditions shall be governed as
                                per the laws of India forthe time being in force
                                and the courts at Bangalore shall have exclusive
                                jurisdiction over any and all matters, claims or
                                disputesarising from these terms.
                              </li>
                              <li>
                                You shall strictly maintain the confidentiality
                                6 pertaining to each deal/ booking and other
                                information pertaining to Us and shall treat all
                                information received from US as confidential.
                                You shall not use, disclose or make public any of
                                the confidential information it may have received
                                from Us other than for the purposes set
                                forthherein
                              </li>
                              <li>
                                We may terminate this agreement in the event You
                                breach the Terms-CL.
                              </li>
                              <li>
                                You have the right to terminate this agreement
                                with immediate effect only in the event if there
                                is a modification of a material term of this
                                agreement and You do not agree to the same. In
                                all other cases, You shall be required to give at
                                least a thirty (30) days prior written notice
                                before terminating the service.
                              </li>
                              <li>
                                On the termination of this agreement, you shall
                                cease performing its services and shall:
                              </li>
                              <ol type="i">
                                <li>
                                  return to us any and all materials and
                                  information received from us. In the event any
                                  information is stored electronically then You
                                  shall remove the same and issue a certificate
                                  to that effect and;
                                </li>
                                <li>
                                  provide us updated information of all the
                                  leads generated in the thirty(30) days prior
                                  to notice of termination.
                                </li>
                              </ol>
                              <br />
                              <p>
                                Provided, you will be provided reasonable time to
                                request
                                <a href="mailto:support@designcafe.com">
                                  support@designcafe.com
                                </a>
                                relevant information related to payments made
                                under this agreement, including any taxation
                                reports/TDS certificates.
                              </p>
                              <li>
                                We may retain certain information for such
                                durations as may be necessary under applicable
                                law including registered mobile
                                number, registered email-ID, GSTIN and other
                                transaction related information. Such information
                                is being retained for audit purposes and to
                                prevent fraudulent acts by You in the future. If
                                You decide to provide services to us again, you
                                may able to create a new account subject to Our
                                discretion.
                              </li>
                            </ol>
                            <li>
                              We may assign, or transfer any or all rights and
                              obligations that accrued in our favour under this
                              Privacy Policy, at our sole discretion, to our
                              subsidiaries, affiliated entities, or any
                              other third party. You, however, are not permitted
                              to assign or transfer any right or obligation that
                              has accrued or may accrue to you underthis Terms
                              -CL. Any attempt by you to assign or transfer such
                              rights and obligations, shall be null and void
                            </li>
                            <li>Grievance officer</li>
                          </ol>
                          <p>
                            We have appointed a data grievance officer. Our data
                            grievance officer is: Amar Krishna Murthy accessible
                            via email at:{" "}
                            <a href="amark@designcafe.com">
                              amark@designcafe.com
                            </a>
                            . You can contact the officer confidentially by email
                            to enquire about the treatment of your personal
                            information.
                          </p>
                            </div>
                    </div>
                        <div className="modal-foot" style={{ textAlign: "center" }}>
                          <button
                            className="submitBtn"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={(event: any) => {
                              this.props.onTermsAccepted(event);
                            }}
                          >
                            I Agree and Accept
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default Documents;
