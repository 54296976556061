import React from "react";
import { Link } from "react-router-dom";
import "./SendOtp.css";
import dclogo from "../../../assets/images/DClogo.png";
interface SendOtpProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SendOtp: React.FC<SendOtpProps> = (sendOtpProps: SendOtpProps) => {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <div className="container d-flex align-items-center justify-content-center flex-column">
        <div className="cardM">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div className="pt-1">
              <img className="pt-5 dcLogo" src={dclogo} alt="DC Logo"></img>
            </div>
            <p className="loginText pt-3">Login</p>
            <p className="pt-3 pt-sm-5 pt-md-5 pt-lg-5 pt-xl-5 mobileNo mb-0">Mobile Number</p>
            <div className="d-flex pt-2">
              <div className="inpBox"></div>
              <input className="inputOtp" type="number"
                onInput={(event: any) => {
                  event.target.value = event.target.value.slice(0, 10)
                  sendOtpProps.onChange(event)
                }}
              ></input>
            </div>
            <p className="pt-3 otpText">We will send you a OTP on this mobile number.</p>
            <br />
            <div className="pt-2 pt-sm-2 pt-md-4 pt-lg-4 pt-xl-4 pt-sm-1">
              <button className="sendBtn" onClick={sendOtpProps.onClick}>
                Get OTP
              </button>
            </div>
            <div>
              <p className="pt-3 otpText otpText-mob">
                Don’t have an account yet?
                <Link className="otpText-extra" to="/Register">
                  {" "}
                  Become our partner
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SendOtp;
