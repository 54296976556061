import React from "react";
import { ComponentBase } from "resub";
import "./Profile.css";
import ImageAssets from "../../../assets/ImageAssets";
import "../../../CSS/Main.css";
import {
  IProfileState,
  ProfileViewModel,
} from "../../../view-model/ProfileViewModel";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import { isNullOrUndefined } from "util";
import SnackBarComponent from "../../../components/SnackBarComponent/SnackBarComponent";
import { Modal } from "@material-ui/core";

class Profile extends ComponentBase<any, IProfileState> {
  viewModel: ProfileViewModel;

  onchangeevent = (event: any) => {
    const value = event.target?.value;
    const element = event.target.id;
    if (element === "name") {
      this.viewModel.set("name", value);
    } else if (element === "mobileNumber") {
      this.viewModel.set("mobileNumber", value);
    } else if (element === "email") {
      this.viewModel.set("email", value);
    } else if (element === "region") {
      this.viewModel.set("region", value);
    } else if (element === "cmm") {
      this.viewModel.set("cmm", value);
    }
  };

  onSubmitClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
    await this.viewModel.submitProfile();
  };

  constructor(props: any) {
    super(props);
    this.viewModel = DependencyInjector.default().provideProfileViewModel();
  }

  componentDidMount(): void {
    this.viewModel.load();
  }
  onChange = (event: any) => {
    const value = event.target.value;
    const element = event.target.id;
    if (element === "region") {
      this.viewModel.set("region", value);
    } else if (element === "manager") {
      this.viewModel.set("marketingManagerId", value);
      this.viewModel.set("selectedMarketingManager", value);
    } else if (element === "email") {
      this.viewModel.set("email", value);
    }
  };

  onSubmitMore = () => {
    this.viewModel.setMany({
      isProfileSubmitedSucessfully: false,
    });
  };

  render() {
    const regions: any = [];
    const marketingManager: any[] = [];
    let snackBarComponent = null;
    for (let val of this.state.regions) {
      regions.push(
        <option key={val} value={val}>
          {val}
        </option>
      );
    }
    for (let val of this.state.cmmName) {
      marketingManager.push(
        <option key={val} value={val}>
          {val}
        </option>
      );
    } 

    let message: string | undefined = "";
    let open = false;
    if (!isNullOrUndefined(this.state.profileError)) {
      message = this.state.profileError.message;
      open = true;
      snackBarComponent = (
        <SnackBarComponent
          message={message}
          onClose={() => {
            this.viewModel.setMany({ profileError: undefined });
          }}
          open={open}
          position={{ vertical: "bottom", horizontal: "center" }}
          severity="error"
        />
      );
    }

    return (
      <div className="profileMain d-flex justify-content-center align-items-center">
        <div>
          <img
            className="mobileImage"
            width="100%"
            src={ImageAssets.dc_SubmitLead_Image}
            alt="Submit lead background"
          ></img>
          <div className="d-flex justify-content-center align-items-center">
            <div className="profileCard">
              <p className="pt-2 HeadText d-flex justify-content-center align-items-center">
                Profile
              </p>
              <div className="formMainProfile d-flex justify-content-center align-items-center flex-column pt-3">
                <div className="form-row">
                  <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                    <label className="pt-2 inputLabel">DSA Code</label>
                    <div className="d-flex">
                      <div className="inpBox"></div>
                      <input
                        className="inputSignup"
                        type="text"
                        id="code"
                        onChange={this.onchangeevent}
                        value={this.state.refreeCode}
                        disabled
                      ></input>
                    </div>
                  </div>
                  <div className="pl-sm-0 pl-md-0 pl-lg-3 pl-xl-3 col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                    <label className="pt-2 inputLabel">Name</label>
                    <div className="d-flex">
                      <div className="inpBox"></div>
                      <input
                        className="inputSignup"
                        type="text"
                        id="name"
                        onChange={this.onchangeevent}
                        value={this.state.name}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                    <label className="pt-2 inputLabel">Phone No.</label>
                    <div className="d-flex">
                      <div className="inpBox"></div>
                      <input
                        className="inputSignup"
                        type="text"
                        id="mobileNumber"
                        onChange={this.onchangeevent}
                        value={this.state.mobileNumber}
                        disabled={true}
                      ></input>
                    </div>
                  </div>
                  <div className="pl-sm-0 pl-md-0 pl-lg-3 pl-xl-3 col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                    <label className="pt-2 inputLabel ">Email id</label>
                    {/* <span className="optionalText pl-2">(optional)</span> */}
                    <div className="d-flex ">
                      <div className="inpBox"></div>
                      <input
                        className="inputSignup"
                        type="text"
                        id="email"
                        onChange={this.onchangeevent}
                        value={this.state.email}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className=" pt-3">
                  <div className="form-row">
                    <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                      <label className="pt-2 inputLabel">Region</label>
                      <div className="d-flex">
                        <div className="inpBox"></div>
                        <select
                          className="inputSignup"
                          id="region"
                          value={this.state.region}
                          onChange={this.onChange}
                        >
                          <option value="">Select one--</option>
                          {regions}
                        </select>
                      </div>
                    </div>
                    <div className="pl-sm-0 pl-md-0 pl-lg-3 pl-xl-3 col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                      <label className="pt-2 inputLabel ">
                        Channel Marketing Manager
                      </label>
                      {/* <span className="optionalText pl-2">(optional)</span> */}
                      <div className="d-flex ">
                        <div className="inpBox"></div>
                        <input
                          className="inputSignup"
                          type="text"
                          id="code"
                          value={this.state.cmmName}
                          disabled={true}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-4 profileButtonLeft d-flex justify-content-center align-items-center">
                  <button
                    className="updateProfile"
                    onClick={this.onSubmitClicked}
                  >
                    Update Details
                  </button>
                </div>
              </div>
              <Modal
                open={this.state.isProfileSubmitedSucessfully}
                onClose={this.onSubmitMore}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                {
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="blueBar"></div>
                      <div className="pt-3 d-flex justify-content-center align-items-center flex-column">
                        <img
                          src={ImageAssets.dc_SubmitLeadSuccess_Image}
                          alt="success logo"
                        ></img>
                        <p className="submitSuccess">Updated Successfully</p>
                        <p className="submitSuccessExtra">
                          The Profile has been Updated sucessfully
                        </p>
                        <div className="d-flex mb-4">
                          <button
                            className="doneBtn"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.onSubmitMore}
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </Modal>
              {snackBarComponent}
            </div>
          </div>
        </div>
      </div>
    );
  }
  protected _buildState() {
    return this.viewModel.getState();
  }
}

export default Profile;
