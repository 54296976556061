import React from "react";
import "./Home.css";
import { ComponentBase } from "resub";
import { HomeViewModel, IHomeState } from "../../../view-model/HomeViewModel";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import NavBar from "../../../components/common/NavBar/NavBar";
import { SearchViewModel } from "../../../view-model/SearchViewModel";
import { BaseState } from "../../../view-model/BaseState";
import { LoginViewModel } from "../../../view-model/LoginViewModel";

export default class Login extends ComponentBase<any, IHomeState> {
  private viewModel: HomeViewModel;
  private searchViewModel: SearchViewModel;
  private baseState: BaseState;
  private loginViewModel: LoginViewModel
  constructor(props: any) {
    super(props);
    this.searchViewModel = DependencyInjector.default().provideSearchViewModel();
    this.viewModel = DependencyInjector.default().provideHomeViewModel();
    this.baseState = DependencyInjector.default().provideBaseState();
    this.loginViewModel = DependencyInjector.default().provideLoginViewModel();
  }

  logOutClicked = (event: any) => {
    const id = event.target.id;
    if (id === "profile") {
      this.props.history.push("/Home/Profile");
    } else if (id === "logout") {
      this.viewModel.logout();
      this.baseState.setMany({ isLoggedIn: false, isOtpGenerated: false });
      this.loginViewModel.setMany({ isOtpGenerated: false });
      localStorage.clear();
      window.location.reload();
      // this.props.history.push("/Login");
    }
  };

  searchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.searchViewModel.setMany({ searchKey: event.target.value });
    if (event.target.value === "") {
      this.props.history.push("/Home");
    } else {
      this.searchViewModel.load();
      this.props.history.push("/Home/Search");
    }
  };

  render() {
    return (
      <div className="">
        <NavBar
          {...this.props}
          onlogoutClicked={this.logOutClicked}
          onChange={this.searchFieldChange}
          logedInUser={this.baseState.state.loggedInUser.partner.name}
        />
      </div>
    );
  }

  protected _buildState() {
    return this.viewModel.getState();
  }
}
