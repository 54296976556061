import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { DashboardRepository } from "../domain/repository/DashboardRepository";
import { ValidationUtils } from "../core/ValidationUtils";
import Messages from "../resources/Strings";
import { MarketingManager } from "../domain/model/MarketingManager";
import { UserRepository } from "../domain/repository/userRepository";

export interface IProfileState {
  isLoading: boolean;
  refreeCode: string;
  name: string;
  mobileNumber: string;
  email: string;
  region: string;
  marketingManagerId: string;
  selectedMarketingManager: string;
  regions: any[];
  marketingManagersInfo: MarketingManager[];
  isProfileSubmitedSucessfully: boolean;
  profileError?: Error;
  cmmName: string;
}

export class ProfileViewModel extends BaseViewModel {
  protected state: IProfileState;
  constructor(
    private baseState: BaseState,
    private dashboardRepository: DashboardRepository,
    private userRepository: UserRepository,
    private validationUtils: ValidationUtils
  ) {
    super();
    this.state = this.defaultState();
  }

  protected setState(newState: IProfileState) {
    this.state = newState;
    this.trigger();
  }

  defaultState(): IProfileState {
    return {
      isLoading: false,
      name: "",
      refreeCode: "",
      mobileNumber: "",
      email: "",
      region: "",
      marketingManagerId: "",
      regions: [],
      marketingManagersInfo: [],
      isProfileSubmitedSucessfully: false,
      selectedMarketingManager: "",
      cmmName: "",
    };
  }

  private validateMobileNumber(): void {
    if (this.validationUtils.isEmpty(this.state.mobileNumber)) {
      this.setMany({
        mobileNumberError: Error(Messages.empty_mobile_number_error),
      });
      return;
    } else if (
      !this.validationUtils.isMobileNumberLengthValid(this.state.mobileNumber)
    ) {
      this.setMany({
        mobileNumberError: Error(Messages.invalid_mobile_number_length_error),
      });
      return;
    }
  }

  private validateSubmitProfile(): void {
    this.validateMobileNumber();
    if (this.validationUtils.isEmpty(this.state.name)) {
      this.setMany({
        profileError: Error(Messages.empty_name),
      });
      return;
    }
    if (this.validationUtils.isEmpty(this.state.email)) {
      this.setMany({
        profileError: Error(Messages.empty_email_error),
      });
      return;
    }
    if (!this.validationUtils.isEmailValid(this.state.email)) {
      this.setMany({
        profileError: Error(Messages.invalid_email),
      });
      return;
    }

    if (!this.state.regions.includes(this.state.region)) {
      this.setMany({
        profileError: Error(Messages.invalid_region),
      });
      return;
    }
    if (!this.validateManager()) {
      this.setMany({
        profileError: Error(Messages.invalid_manager),
      });
      return;
    }
  }

  private validateManager(): boolean {
    for (let { Id } of this.state.marketingManagersInfo) {
      if (Id === this.state.marketingManagerId) {
        return true;
      }
    }
    return false;
  }

  public async load(): Promise<void> {
    try {
      const profile = await this.userRepository.getProfile();
      this.setMany({
        ...this.state,
        refreeCode: profile.body.refreeCode,
        name: profile.body.name,
        email: profile.body.email === null ? "" : profile.body.email,
        mobileNumber: profile.body.phoneNumber,
        region: profile.body.region === null ? "" : profile.body.region,
        cmmName:profile.body.cmmName === null ? "" : profile.body.cmmName,
      });
      const response = await this.userRepository.getManagerAndRegion();
      const cmmId = profile.body.cmmId;
      this.setMany({
        ...this.state,
        regions: response.body.regions,
        marketingManagersInfo: response.body.cmmRecords,
      });
      const cmmName = response.body.cmmRecords.filter((ele: any) => {
        if (ele.Id === cmmId) {
          return ele.Name;
        }
      })[0].Name;

      this.setMany({
        ...this.state,
        marketingManagerId: cmmId,
        selectedMarketingManager: cmmId,
        cmmName,
      });
    } catch (error) {
      this.baseState.set("isLoading", false);
    }
  }

  public async submitProfile(): Promise<void> {
    this.validateSubmitProfile();
    if (this.state.profileError) {
      return;
    }
    try {
      const data = {
        name: this.state.name,
        mobileNumber: this.state.mobileNumber,
        channelMarketingManager: this.state.marketingManagerId,
        region: this.state.region,
        email: this.state.email,
      };
      await this.dashboardRepository.registerCustomer(data);
      this.setState({
        ...this.state,
        isProfileSubmitedSucessfully: true,
      });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
        profileError: error,
      });
    }
  }
}
