import React from "react";
import { ComponentBase } from "resub";
import {
  ISearchState,
  SearchViewModel,
} from "../../../view-model/SearchViewModel";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ImageAssets from "../../../assets/ImageAssets";
import "./Search.css";
import "../../../CSS/Main.css";
import { isNullOrUndefined } from "util";
import { TablePagination } from "@material-ui/core";
import moment from "moment";

export default class Search extends ComponentBase<any, ISearchState> {
  private viewModel: SearchViewModel;

  constructor(props: any) {
    super(props);
    this.viewModel = DependencyInjector.default().provideSearchViewModel();
  }

  componentDidMount(): void {
    this.viewModel.load();
  }

  render() {
    const leadsTableBodyinfo: any = [];

    if (!isNullOrUndefined(this.state.leads) && this.state.leads.length > 0) {
      let index = 0;
      for (let lead of this.state.leads) {
        index++;
        leadsTableBodyinfo.push(
          <TableRow key={index}>
            <TableCell className="UserName" align="center">
              {lead.Name}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.DC_Lead_Status__c}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead?.Email}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.City}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.Call_Stage__c}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.MobilePhone}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead?.CreatedDate
                ? moment(lead?.CreatedDate).format("YYYY-MM-DD")
                : "NA"}
            </TableCell>
          </TableRow>
        );
      }
    }
    const projectsTableBodyinfo: any = [];
    if (
      !isNullOrUndefined(this.state.projects) &&
      this.state.projects.length > 0
    ) {
      let index = 0;
      for (let project of this.state.projects) {
        index++;
        let statusClass = "projectStatus";
        let milestoneClass = "projectStage";
        if (project.status === "On Hold") {
          statusClass = "projectStatusOnHold";
        }
        if (project.milestone === "Factory Dispatch") {
          milestoneClass = "projectStageDispatch";
        }

        projectsTableBodyinfo?.push(
          <TableRow key={index}>
            <TableCell className="UserName" align="justify">
              {project.projectname}
            </TableCell>
            <TableCell align="justify">
              <div className={milestoneClass}>{project.currentmilestone}</div>
            </TableCell>
            <TableCell align="justify">
              <div className={statusClass}>{project.projectstatus}</div>
            </TableCell>
            <TableCell align="justify" className="userDetail">
              {Number(project?.totalprojectvalue)?.toFixed(2)}
            </TableCell>
            <TableCell align="justify" className="userDetail">
              {project.signupdate}
            </TableCell>
            <TableCell align="justify" className="userDetail">
              {project?.expectedhandoverdate}
            </TableCell>
          </TableRow>
        );
      }
    }
    const opportunityTableBodyinfo: any = [];
    if (
      !isNullOrUndefined(this.state.opportunities) &&
      this.state.opportunities.length > 0
    ) {
      let index = 0;
      for (let opportunity of this.state.opportunities) {
        index++;
        opportunityTableBodyinfo.push(
          <TableRow key={index}>
            <TableCell className="UserName" align="justify">
              {opportunity.Name}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.StageName}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.Amount}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.Region__c}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.CloseDate}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.CreatedDate
                ? moment(opportunity.CreatedDate).format("YYYY-MM-DD")
                : "NA"}
              {}
            </TableCell>
          </TableRow>
        );
      }
    }
    return (
      <div>
        {console.log(141, this.state.leads)}
        {this.state.leads && this.state.leads.length > 0 ? (
          <div className="pt-4">
            <div className="searchHeadTextWrap">
              <p className="searchHeadText pt-3 pl-3">Leads</p>
            </div>
            <Paper>
              <TableContainer className="paper" component={Paper}>
                <Table className="" stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="name"
                      >
                        Name
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="leadStatus"
                      >
                        Lead Status
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="email"
                      >
                        Email
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="city"
                      >
                        City
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="leadStage"
                      >
                        Lead Stage
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="phoneNumber"
                      >
                        Mobile
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="createdDate"
                      >
                        Created Date
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{leadsTableBodyinfo}</TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Rows/page"
                count={this.state.LeadsTotalCount}
                rowsPerPage={this.state.pageSize}
                page={this.state.leadPage}
                onChangePage={(event, pageIndex) => {
                  this.viewModel.set("leadPage", pageIndex);
                  this.viewModel.load();
                }}
                onChangeRowsPerPage={(event) => {
                  console.log("pageSize", event.target.value);
                  this.viewModel.set("pageSize", event.target.value);
                  this.viewModel.load();
                }}
              />
            </Paper>
          </div>
        ) : null}
        {this.state.opportunities && this.state.opportunities.length > 0 ? (
          <div className="pt-4">
            <div className="searchHeadTextWrap">
              <p className="searchHeadText pt-3 pl-3">Opportunities</p>
            </div>
            <Paper>
              <TableContainer className="paper" component={Paper}>
                <Table className="" stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="userHead" align="justify" id="name">
                        Opportunity Name{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="stageName"
                      >
                        Opportunity Stage{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="amount"
                      >
                        Amount{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="region"
                      >
                        Region{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="closeDate"
                      >
                        Close Date{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="createdDate"
                      >
                        Created Date{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{opportunityTableBodyinfo}</TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Rows/page"
                count={this.state.OpportunitiesTotalCount}
                rowsPerPage={this.state.pageSize}
                page={this.state.opportunityPage}
                onChangePage={(event, pageIndex) => {
                  this.viewModel.set("opportunityPage", pageIndex);
                  this.viewModel.load();
                }}
                onChangeRowsPerPage={(event) => {
                  console.log("pageSize", event.target.value);
                  this.viewModel.set("pageSize", event.target.value);
                  this.viewModel.load();
                }}
              />
            </Paper>
          </div>
        ) : null}
        {this.state.projects && this.state.projects.length > 0 ? (
          <div className="pt-4">
            <div className="searchHeadTextWrap">
              <p className="searchHeadText pt-3 pl-3">Projects</p>
            </div>
            <Paper>
              <TableContainer className="paper" component={Paper}>
                <Table className="paper" stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="userHead" align="justify" id="name">
                        Project Name{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="milestone"
                      >
                        Project stage{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="status"
                      >
                        Status{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="amount"
                      >
                        Amount{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="startDate"
                      >
                        Start Date{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="endDate"
                      >
                        Tentative End Date{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{projectsTableBodyinfo}</TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Rows/page"
                count={this.state.ProjectsTotalCount}
                rowsPerPage={this.state.pageSize}
                page={this.state.projectPage}
                onChangePage={(event, pageIndex) => {
                  this.viewModel.set("projectPage", pageIndex);
                  this.viewModel.load();
                }}
                onChangeRowsPerPage={(event) => {
                  console.log("pageSize", event.target.value);
                  this.viewModel.set("pageSize", event.target.value);
                  this.viewModel.load();
                }}
              />
            </Paper>
          </div>
        ) : null}
      </div>
    );
  }

  protected _buildState() {
    return this.viewModel.getState();
  }
}
