import React from "react";
import dclogo from "../../../assets/images/DClogo.png";
import laptop from "../../../assets/images/Signup_laptop.png";
import "./Verification.css";
import ".././Information/Information.css";
import "../.././auth/SendOtp/SendOtp.css";

interface VerificationProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resendOtp: (event: any) => void;
}

const Verification: React.FC<VerificationProps> = (verificationProps: VerificationProps) => {
  return (
    <div className=" d-flex align-items-center justify-content-center flex-column">
      <div className="container d-flex align-items-center justify-content-center flex-column">
        <div className="cardSignup">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div className="pt-3">
              <img className="" src={dclogo} alt="DC Logo"></img>
            </div>
            <p className="loginText pt-3">Become our Patner</p>
            <div className="">
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <p className="signupText">Personal Information</p>
                  <div className="infoBar"></div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <p className="signupText-blur">Documents</p>
                  <div className="infoBar-blur"></div>
                </div>
              </div>
            </div>
            <div className="pt-4 mr-3">
              <img src={laptop} alt="laptop"></img>
            </div>
            <p className="pt-3 plainText">Please enter 6 digit code sent to your mobile number</p>
            <div className="d-flex pt-2">
              <div className="inpBox"></div>
              <input className="inputOtp" type="text" id="otp" onInput={verificationProps.onChange}></input>
            </div>
            <br />
            <p className="pt-3 otpText mb-0">Didn’t receive the OTP yet?</p>
            <p className="pt-1 resend" onClick={verificationProps.resendOtp}>Resend OTP</p>
            <div className="pt-3">
              <button className="verifyNum" onClick={verificationProps.onClick}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div>
    //   <p>Login</p>
    //   <label>Verification</label>
    //   <input type="number" onInput={verificationProps.onChange}></input>
    //   <br />
    //   <label>we will send OTP on this mobile number</label>
    //   <br />
    //   <button onClick={verificationProps.onClick}>Get Otp</button>
    //   <p>
    //     Dont have an account yet? <a href="/">Become our partner</a>
    //   </p>
    // </div>
  );
};
export default Verification;
